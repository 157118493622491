import { OauthAttemptStatus } from "@coeff/api";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { ConnectPlugIcon, Typography } from "../components";

const Layout = styled.div`
  height: 100vh;
  text-align: center;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const OauthAttemptRedirect: React.FC = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const isSuccessStatus = searchParams.get("status") === OauthAttemptStatus.Success;

  const isFromAddon = searchParams.get("from") === "addon";

  useEffect(() => {
    // This will only work if the tab was created using javascript.
    // Otherwise it will not have permission to close itself.
    if (isSuccessStatus || isFromAddon) {
      window.close();
    }
  }, [isSuccessStatus, isFromAddon]);

  if (isSuccessStatus) {
    return (
      <Layout>
        <ConnectPlugIcon />

        <div>
          <Typography variant="body1" fontWeight={600}>
            Your connection was successful!
          </Typography>

          <Typography>You can close this page and return to your worksheet.</Typography>
        </div>
      </Layout>
    );
  }

  const errorMessage = searchParams.get("error_message");
  const errorTitle = searchParams.get("error_title");

  return (
    <Layout>
      <ConnectPlugIcon />

      <div>
        <Typography variant="body1" fontWeight={600}>
          {errorTitle ?? "An error occurred while creating your connection."}
        </Typography>

        <Typography>{errorMessage ?? "Please close this page and try again."}</Typography>
      </div>
    </Layout>
  );
};
