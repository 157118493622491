import {
  DataSourceType,
  PivotDimensionBucketingType,
  PivotValueAggregationType,
  SortType,
} from "@coeff/api";

export * from "./free-domains";
export * from "./data-source";
export * from "./assets";

export const FILE_BASED_GOOGLE_CONNECTORS: DataSourceType[] = [
  DataSourceType.GoogleDrive,
  DataSourceType.Csv,
  DataSourceType.GoogleSheets,
];

export const GPT_SQL_SUPPORTED_DATASOURCES: DataSourceType[] = [
  "mysql",
  "postgres",
  "redshift",
  "mssql",
  "snowflake",
  "databricks",
];

export const PPRESS_DATASOURCES: Array<DataSourceType> = [
  "jira",
  "instagram_insights",
  "stripe",
  "zendesk",
  "quickbooks",
  "shopify",
  "airtable",
  "mailchimp",
  "pipedrive",
  "one_drive",
  "outreach",
  "google_ads",
  "google_analytics_4",
  "facebook_ads",
  "google_search_console",
  "chargebee",
  "notion",
  "linkedin_ads",
  "facebook_insights",
  "youtube_analytics",
  "linkedin_pages",
  "custom_api",
  "microsoft_ads",
  "netsuite",
  "xero",
  "klaviyo",
  "asana",
  "razorpay",
  "clickup",
  "typeform",
  "harvest",
  "freshdesk",
  "paymo",
  "google_calendar",
  "trello",
  "gong",
  "calendly",
  "smartsheet",
  "reddit_ads",
  "surveymonkey",
  "quora_ads",
  "podio",
  "greenhouse",
  "gusto",
  "intercom",
  "etsy",
  "pinterest_ads",
  "active_campaign",
  "basecamp",
  "jotform",
  "zohobooks",
  "coda",
  "square",
  "zoom",
  "webflow",
  "zoho_crm",
  "bigin",
  "lever",
  "salesloft",
  "wordpress",
  "woo_commerce",
  "bigcommerce",
  "smartlead",
  "affinity",
  "looker",
  "amplitude",
  "shiprocket",
  "rippling",
  "apollo",
  "aws_billing",
  "gmail",
  "sage",
  "google_classroom",
];

export const PPRESS_REAUTH_DATASOURCES: Array<DataSourceType> = [
  ...PPRESS_DATASOURCES,
  "salesforce",
  "hubspot",
  "snowflake",
  "looker",
];

export const SQL_DATASOURCES: Array<DataSourceType> = [
  "mysql",
  "postgres",
  "redshift",
  "snowflake",
  "mssql",
  "bigquery",
  "cloud_table",
  "databricks",
];

export const CUSTOM_API_DATASOURCES: Array<DataSourceType> = [
  "custom_api",
  "xero",
  "asana",
  "klaviyo",
  "razorpay",
  "clickup",
  "typeform",
  "harvest",
  "freshdesk",
  "paymo",
  "google_calendar",
  "trello",
  "gong",
  "calendly",
  "smartsheet",
  "reddit_ads",
  "surveymonkey",
  "quora_ads",
  "podio",
  "greenhouse",
  "gusto",
  "intercom",
  "etsy",
  "pinterest_ads",
  "active_campaign",
  "basecamp",
  "jotform",
  "zohobooks",
  "coda",
  "square",
  "zoom",
  "webflow",
  "zoho_crm",
  "bigin",
  "lever",
  "salesloft",
  "wordpress",
  "woo_commerce",
  "bigcommerce",
  "smartlead",
  "affinity",
  "shiprocket",
  "rippling",
  "apollo",
  "google_classroom",
];

export const SQL_FLOW_REUSE_DATASOURCES: Array<DataSourceType> = ["mongodb"];

export const WRITEBACK_SUPPORTED_DATASOURCES: Array<DataSourceType> = [
  DataSourceType.Salesforce,
  DataSourceType.Mysql,
  DataSourceType.Postgres,
  DataSourceType.Hubspot,
  DataSourceType.Snowflake,
];

export const NESTED_FILTERS_SUPPORTED_DATA_SOURCES = [
  "hubspot",
  "postgres",
  "mysql",
  "redshift",
  "mssql",
  "bigquery",
  "snowflake",
  "salesforce",
  "databricks",
  "looker",
];

export const WRITEBACK_COMING_SOON_DATASOURCES: Array<DataSourceType> = ["snowflake", "quickbooks"];

const DATA_IMPORT_RECIPES_DISABLED_PPRESS_DATA_SOURCE_TYPES: Set<DataSourceType> = new Set([
  "amplitude",
  "gmail",
] as Array<DataSourceType>);

export const DATA_IMPORT_RECIPES_SUPPORTED_DATA_SOURCE_TYPES: Array<DataSourceType> = [
  "hubspot",
  "salesforce",
  "google_analytics",
  ...PPRESS_DATASOURCES.filter(
    dataSource => !DATA_IMPORT_RECIPES_DISABLED_PPRESS_DATA_SOURCE_TYPES.has(dataSource)
  ),
  // All SQL datasources except Cloud Tables
  ...([
    "mysql",
    "postgres",
    "redshift",
    "snowflake",
    "mssql",
    "bigquery",
    "mongodb",
    "databricks",
  ] as Array<DataSourceType>),
];

export const SINGLE_MODE_PICKLIST_DATASOURCES: Array<DataSourceType> = [
  "microsoft_ads",
  "youtube_analytics",
];

export const OAUTH_DATASOURCES = ["bigquery", "google_drive", "one_drive", "amplitude"];

export const PPRESS_API_KEY_DATASOURCES = [
  "chargebee",
  "klaviyo",
  "razorpay",
  "freshdesk",
  "paymo",
  "trello",
  "greenhouse",
  "active_campaign",
  "coda",
  "jotform",
  "wordpress",
  "woo_commerce",
  "smartlead",
  "affinity",
  "shiprocket",
  "rippling",
  "apollo",
  "aws_billing",
  "sage",
];

export const MULTIPLE_OAUTH_DATASOURCES = [
  "quickbooks",
  "linkedin_ads",
  "linkedin_pages",
  "youtube_analytics",
  "pipedrive",
  "airtable",
  "stripe",
  "google_search_console",
  "facebook_insights",
  "google_ads",
  "mailchimp",
  "outreach",
  "facebook_ads",
  "google_analytics_4",
  "hubspot",
  "salesforce",
  "instagram_insights",
  "jira",
  "google_analytics",
  "notion",
  "zendesk",
  "shopify",
  "microsoft_ads",
  "netsuite",
  "xero",
  "asana",
  "clickup",
  "typeform",
  "google_calendar",
  "gmail",
  "gong",
  "calendly",
  "smartsheet",
  "reddit_ads",
  "surveymonkey",
  "quora_ads",
  "harvest",
  "podio",
  "gusto",
  "intercom",
  "etsy",
  "pinterest_ads",
  "basecamp",
  "zohobooks",
  "square",
  "zoom",
  "webflow",
  "zoho_crm",
  "bigin",
  "lever",
  "salesloft",
  "bigcommerce",
  "google_classroom",
];

export const PHASE_TWO_MULTIPLE_OAUTH_DATASOURCES = [
  "jira",
  "google_analytics",
  "airtable",
  "pipedrive",
  "stripe",
  "mailchimp",
  "notion",
  "zendesk",
  "shopify",
];

export const DATA_SOURCES_IN_BETA = ["amplitude", "gmail", "sage"];

export const SHAREABLE_PPRESS_DATA_SOURCES: Array<DataSourceType> = [
  "quickbooks",
  "linkedin_ads",
  "linkedin_pages",
  "youtube_analytics",
  "google_search_console",
  "facebook_insights",
  "google_ads",
  "outreach",
  "facebook_ads",
  "mailchimp",
  "google_analytics_4",
  "chargebee",
  "instagram_insights",
  "google_analytics",
  "jira",
  "stripe",
  "notion",
  "zendesk",
  "pipedrive",
  "airtable",
  "shopify",
  "bigquery",
  "microsoft_ads",
  "netsuite",
  "hubspot",
  "salesforce",
  "xero",
  "klaviyo",
  "asana",
  "razorpay",
  "clickup",
  "typeform",
  "harvest",
  "freshdesk",
  "paymo",
  "google_calendar",
  "trello",
  "gong",
  "calendly",
  // Special case
  "looker",
  "snowflake",
  "smartsheet",
  "reddit_ads",
  "surveymonkey",
  "quora_ads",
  "podio",
  "greenhouse",
  "gusto",
  "intercom",
  "etsy",
  "pinterest_ads",
  "active_campaign",
  "basecamp",
  "jotform",
  "zohobooks",
  "coda",
  "square",
  "zoom",
  "webflow",
  "zoho_crm",
  "bigin",
  "lever",
  "salesloft",
  "wordpress",
  "woo_commerce",
  "bigcommerce",
  "smartlead",
  "affinity",
  "amplitude",
  "shiprocket",
  "databricks",
  "rippling",
  "apollo",
  "aws_billing",
  "sage",
  "google_classroom",
];

export const NON_CONNECTOR_DATA_SOURCE_TYPES: Array<DataSourceType> = [
  DataSourceType.GptCopilot,
  DataSourceType.GoogleDrive,
  DataSourceType.OneDrive,
];

export const DATA_SOURCES_WITH_MULTIPLE_CONNECT_MODES = ["bigquery", "snowflake", "looker"];

export const DATA_SOURCES_USE_SECONDARY_SQL_CONNECTION_FORM: Array<DataSourceType> = [
  "mongodb",
  "databricks",
];

export enum AuthType {
  PPRESS = "PPRESS",
  OAUTH = "OAUTH",
  API_KEY = "API_KEY",
  SQL = "SQL",
  AMPLITUDE = "AMPLITUDE",
  TABLEAU = "TABLEAU",
  LOOKER = "LOOKER",
  NONE = "NONE",
  NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
}

export const DATA_SOURCE_AUTH_TYPE: Record<DataSourceType, AuthType> = {
  // LOOKER
  looker: AuthType.LOOKER,

  // TABLEAU
  tableau: AuthType.TABLEAU,

  // AMPLITUDE
  amplitude: AuthType.AMPLITUDE,

  // OAUTH
  csv: AuthType.OAUTH,
  google_drive: AuthType.OAUTH,
  google_sheets: AuthType.OAUTH,
  excel: AuthType.OAUTH,
  google_analytics: AuthType.OAUTH,

  // SQL
  mysql: AuthType.SQL,
  redshift: AuthType.SQL,
  postgres: AuthType.SQL,
  mssql: AuthType.SQL,
  bigquery: AuthType.SQL,
  databricks: AuthType.SQL,

  // PPRESS
  jira: AuthType.PPRESS,
  instagram_insights: AuthType.PPRESS,
  stripe: AuthType.PPRESS,
  zendesk: AuthType.PPRESS,
  hubspot: AuthType.PPRESS,
  quickbooks: AuthType.PPRESS,
  shopify: AuthType.PPRESS,
  google_ads: AuthType.PPRESS,
  google_analytics_4: AuthType.PPRESS,
  facebook_ads: AuthType.PPRESS,
  facebook_insights: AuthType.PPRESS,
  mongodb: AuthType.PPRESS,
  snowflake: AuthType.PPRESS,
  salesforce: AuthType.PPRESS,
  airtable: AuthType.PPRESS,
  mailchimp: AuthType.PPRESS,
  pipedrive: AuthType.PPRESS,
  one_drive: AuthType.PPRESS,
  outreach: AuthType.PPRESS,
  google_search_console: AuthType.PPRESS,
  chargebee: AuthType.PPRESS,
  notion: AuthType.PPRESS,
  linkedin_ads: AuthType.PPRESS,
  linkedin_pages: AuthType.PPRESS,
  youtube_analytics: AuthType.PPRESS,
  microsoft_ads: AuthType.PPRESS,
  netsuite: AuthType.PPRESS,
  xero: AuthType.PPRESS,
  klaviyo: AuthType.PPRESS,
  asana: AuthType.PPRESS,
  razorpay: AuthType.PPRESS,
  clickup: AuthType.PPRESS,
  typeform: AuthType.PPRESS,
  harvest: AuthType.PPRESS,
  freshdesk: AuthType.PPRESS,
  paymo: AuthType.PPRESS,
  google_calendar: AuthType.PPRESS,
  trello: AuthType.PPRESS,
  gong: AuthType.PPRESS,
  calendly: AuthType.PPRESS,
  smartsheet: AuthType.PPRESS,
  reddit_ads: AuthType.PPRESS,
  surveymonkey: AuthType.PPRESS,
  quora_ads: AuthType.PPRESS,
  podio: AuthType.PPRESS,
  greenhouse: AuthType.PPRESS,
  gusto: AuthType.PPRESS,
  intercom: AuthType.PPRESS,
  etsy: AuthType.PPRESS,
  pinterest_ads: AuthType.PPRESS,
  active_campaign: AuthType.PPRESS,
  basecamp: AuthType.PPRESS,
  jotform: AuthType.PPRESS,
  zohobooks: AuthType.PPRESS,
  coda: AuthType.PPRESS,
  square: AuthType.PPRESS,
  zoom: AuthType.PPRESS,
  webflow: AuthType.PPRESS,
  zoho_crm: AuthType.PPRESS,
  bigin: AuthType.PPRESS,
  lever: AuthType.PPRESS,
  salesloft: AuthType.PPRESS,
  wordpress: AuthType.PPRESS,
  woo_commerce: AuthType.PPRESS,
  bigcommerce: AuthType.PPRESS,
  smartlead: AuthType.PPRESS,
  affinity: AuthType.PPRESS,
  shiprocket: AuthType.PPRESS,
  rippling: AuthType.PPRESS,
  apollo: AuthType.PPRESS,
  aws_billing: AuthType.PPRESS,
  gmail: AuthType.PPRESS,
  sage: AuthType.PPRESS,
  google_classroom: AuthType.PPRESS,

  // NONE
  gpt_copilot: AuthType.NONE,
  cloud_table: AuthType.NONE,
  custom_api: AuthType.NONE,

  // NOT IMPLEMENTED
  chargify: AuthType.NOT_IMPLEMENTED,
  marketo: AuthType.NOT_IMPLEMENTED,
  sendgrid: AuthType.NOT_IMPLEMENTED,
  web_scraper: AuthType.NOT_IMPLEMENTED,
};

export const DISABLED_PREVIEW_PPRESS_DATASOURCES = ["quickbooks", "shopify", "google_analytics_4"];

export const COLORS = {
  // NEUTRALS
  black: "#000000",
  black2: "#FAFAFA",
  black4: "#F5F5F5",
  black5: "#F9F9F9",
  black6: "#EFEFEF",
  black9: "#E8E8E8",
  black10: "#EEEEEE",
  black15: "#D9D9D9",
  black20: "#CECECE",
  black25: "#BFBFBF",
  black35: "#979797",
  black45: "#868686",
  black50: "#727272",
  black60: "#5f5f5f",
  black65: "#595959",
  black70: "#454545",
  black85: "#262626",
  white: "#FFFFFF",

  // PRIMARIES
  coeblue: "#E6F3FF",
  coeblue1: "#A3D1FF",
  coeblue2: "#7AB8FF",
  coeblue3: "#529DFF",
  coeblue4: "#3053FF",
  coeblue5: "#0054E6",
  coeblue6: "#0040BF",
  coeblue7: "#002E99",
  coeblue75: "#0026E2",
  coeblue8: "#001F73",
  coeblue9: "#00124D",
  coeblue10: "#F8FAFF",
  coeblue11: "#EFF4FE",
  coeblue12: "#DEE9FE",

  // SECONDARIES
  lightBlue: "#7DBCFF",
  blue: "#D9E6FB",
  cyan: "#13C2C2",
  cyan2: "#ADE7FF",
  gold: "#FAAD14",
  gray: "#8C8C8C",
  green: "#52C41A",
  green2: "#0F9D58",
  green3: "#00a300",
  lime: "#A0D911",
  magenta: "#EB2F96",
  orange: "#FA8C16",
  orange2: "#F8B000",
  purple: "#722ED1",
  red: "#F5222D",
  red2: "#FA1616",
  red3: "#FDD",
  red4: "#DE1135",
  volcano: "#FA541C",
  teal: "#13C2C2",
  yellow: "#FAC82A",
  yellow2: "#f8b000",
  yellowLight: "#FFE07D",
  lightGreenBg: "#DBF6F0",
  greenBg: "#D3F2F3",
  greenBg2: "#D7F5E7",
  redBg: "#FEE3E5",
  redBg2: "#FFE1DE",
  orangeBg: "#FDF2DC",
  orangeBg2: "#FFF3DC",
  orangeBg3: "#FEEDCB",
  orangeBg4: "#FDF2DC",
  purpleBg: "#F1E8FF",
  purpleBg2: "#E4D5FD",
  purpleBg3: "#D1BAF3",
  seafoamBg: "#E0F6F6",
  seafoam: "#08979C",
  yellowBg: "#FFF2D8",
  yellowBg2: "#FBE5B6",
  aeroBlue: "#D3F6E5",
  magnolia: "#F5E9FF",
  water: "#DBF5F5",
  silk: "#FFF7DF",
  magentaBg: "#FEEFF9",

  // SPECIAL
  parameters: "#77B2FF",

  // GPT
  editorPurpleBg: "#142163",
  buttonPurpleBg: "#293886",
  buttonPurpleBgHover: "#3E4FA6",
  purpleText: "#F35DF6",
  purpleText2: "#D13AD6",
  purpleDark: "#8320C0",
};

export const DB_DEFAULT_PORTS: Partial<Record<DataSourceType, number>> = {
  mysql: 3306,
  postgres: 5432,
  redshift: 5439,
  snowflake: 443,
  mssql: 1433,
};

export const ERROR_TITLES = {
  GENERIC_ERROR: "Uh-oh. Something went wrong.",
};

export const ERROR_MESSAGES = {
  GENERIC_ERROR: "If the issue persists, please send us a message at help@coefficient.io",
};

export const WARNING_TITLES = {
  GENERIC_WARNING: "Uh-oh. Something went wrong.",
};

export const WARNING_MESSAGES = {
  GENERIC_WARNING: "",
};

export const LEAD_FORM_URL = "https://coefficient837626.typeform.com/to/YUlLtu";

export const HEIGHT = {
  xxs: "16px",
  xs: "20px",
  sm: "24px",
  md: "32px",
  lg: "40px",
};

export const TEXT_SIZE = {
  lg: "16px",
  md: "14px",
  sm: "12px",
};

export const LOGO_SIZE = {
  width: "120px",
};

export const PIVOT_SORT_TYPES: SortType[] = ["asc", "desc"];

export const SORT_TYPES: Array<{
  dataKey: string;
  label: string;
}> = [
  { dataKey: "asc", label: "↑ ASC" },
  { dataKey: "desc", label: "↓ DESC" },
];

export const HOURLY = [
  { label: "Every hour", value: 1 },
  { label: "Every 2 hours", value: 2 },
  { label: "Every 4 hours", value: 4 },
  { label: "Every 8 hours", value: 8 },
];

export const WEEKDAYS = [
  { dataKey: 0, label: "Sunday", shortLabel: "Sun" },
  { dataKey: 1, label: "Monday", shortLabel: "Mon" },
  { dataKey: 2, label: "Tuesday", shortLabel: "Tue" },
  { dataKey: 3, label: "Wednesday", shortLabel: "Wed" },
  { dataKey: 4, label: "Thursday", shortLabel: "Thu" },
  { dataKey: 5, label: "Friday", shortLabel: "Fri" },
  { dataKey: 6, label: "Saturday", shortLabel: "Sat" },
];

export const MONTHLY = [{ label: "Last Day", value: 31 }];

export const PIVOT_AGGREGATION: PivotValueAggregationType[] = [
  "count",
  "sum",
  "countunique",
  "average",
  "max",
  "min",
];

export const BUCKETING_TYPE: PivotDimensionBucketingType[] = ["day", "month", "quarter", "year"];
