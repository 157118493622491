import "antd/dist/antd.css";
import "./globals.css";

import { MsalProvider } from "@azure/msal-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { notification } from "antd";
import { message } from "antd";
import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { PreloadedState } from "redux";
import { ThemeProvider } from "styled-components";

import { DEPRECATED_ApiManager, initApiClients, initQueryClient } from "./api";
import { ZendeskProvider } from "./contexts/zendeskContext";
import { initialAppState } from "./reducers/app";
import { AuthGuard } from "./routes/AuthGuard";
import { OauthAttemptRedirect } from "./routes/OauthAttemptRedirect";
import Root from "./routes/Root";
import { configureStore, history, AppState } from "./store";
import {
  BroadcastChannelName,
  GoogleApi,
  errorToAuthErrorMessage,
  initPublicClientApp,
  theme,
  track,
} from "./utils";
import { ApiContext, GoogleApiContext, TrackContext } from "./utils/contexts";
import { initSentry } from "./utils/sentry";

const init = async () => {
  const publicClientApp = await initPublicClientApp();

  const queryClient = initQueryClient();

  const urlParams = new URLSearchParams(window.location.search);

  const urlAuthParams = {
    coeffAuthToken: urlParams.get("COEFF-AUTH-TOKEN") ?? "",
    msOauthToken: urlParams.get("MICROSOFT-OAUTH-TOKEN") ?? "",
    openIdToken: urlParams.get("GOOGLE-OPENID-TOKEN") ?? "",
    oauthToken: urlParams.get("GOOGLE-OAUTH-TOKEN") ?? "",
    upgradeToken: urlParams.get("ut") ?? "",
    domain: urlParams.get("domain") ?? "",
  };

  const preloadedState: PreloadedState<AppState> = {
    app: {
      ...initialAppState,
      ...urlAuthParams,
    },
  };

  const DEPRECATED_api = new DEPRECATED_ApiManager({
    publicClientApp,
    authParams: urlAuthParams,
    onUnauthorizedError: error => {
      authBroadcastChannel.postMessage(errorToAuthErrorMessage(error));
    },
  });

  const store = configureStore(preloadedState, { api: DEPRECATED_api, history });

  const authBroadcastChannel = new BroadcastChannel(BroadcastChannelName.AUTH);

  const apiClients = initApiClients({
    publicClientApp,
    store,
    onUnauthorizedError: error => {
      authBroadcastChannel.postMessage(errorToAuthErrorMessage(error));
    },
  });

  initSentry();

  notification.config({
    placement: "bottomRight",
    duration: 5,
  });

  message.config({
    maxCount: 1,
  });

  render(
    <MsalProvider instance={publicClientApp}>
      <TrackContext.Provider value={(eventName, event) => track(DEPRECATED_api, eventName, event)}>
        <ApiContext.Provider value={{ DEPRECATED_api, ...apiClients }}>
          <GoogleApiContext.Provider value={{ googleApiClient: new GoogleApi() }}>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <ZendeskProvider>
                  <Router history={history}>
                    <ThemeProvider theme={theme}>
                      <Switch>
                        <Route exact path="/oauth_attempt" component={OauthAttemptRedirect} />
                        <Route
                          path="*"
                          render={() => (
                            <AuthGuard>
                              <Root />
                            </AuthGuard>
                          )}
                        />
                      </Switch>
                    </ThemeProvider>
                  </Router>
                </ZendeskProvider>
              </Provider>
            </QueryClientProvider>
          </GoogleApiContext.Provider>
        </ApiContext.Provider>
      </TrackContext.Provider>
    </MsalProvider>,
    document.getElementById("root")
  );
};

init();
