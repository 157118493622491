import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { AppState } from "src/store";
import { StatsigSynchronousProvider } from "statsig-react";

import { TopLevelError } from "../components/TopLevelError";
import { BuildFlags } from "../constants";
import { DefaultLayout } from "../layouts";
import { fetchStatsigUser } from "../utils/statsigHelper";

import { Billing } from "./Billing/Billing";
import { CloudTableDetails } from "./CloudTableDetails/CloudTableDetails";
import { CoeffAdmin } from "./CoeffAdmin/CoeffAdmin";
import { VCRDebugger } from "./CoeffAdmin/VCRDebugger";
import { CreateCloudTable } from "./CreateCloudTable/CreateCloudTable";
import { Dashboard } from "./Dashboard/Dashboard";
import { CompleteLookerSetup } from "./LookerCompleteSetup";
import { RequestLookerSetup } from "./LookerRequestSetup";
import { NotFound } from "./NotFound";
import { WebScraperAuthSession } from "./WebScraperSession/WebScraperAuthSession";

const SuperAdminRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <DefaultLayout>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/domains`} />
        </Route>
        <Route path={`${path}/domains`}>
          <CoeffAdmin />
        </Route>
        <Route path={`${path}/domainDetails`}>
          <CoeffAdmin />
        </Route>
        <Route path={`${path}/quickstart`}>
          <CoeffAdmin />
        </Route>
        <Route path={`${path}/vcr_debugger`}>
          <VCRDebugger />
        </Route>
      </Switch>
    </DefaultLayout>
  );
};

const CoeffAdminRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <DefaultLayout>
      <Switch>
        <Redirect exact from={path} to="/superadmin" />
        <Redirect from="/coeffadmin/data" to="/superadmin/domains" />
        <Redirect from="/coeffadmin/:page" to="/superadmin/:page" />
      </Switch>
    </DefaultLayout>
  );
};

const DomainAdmin_OLD = () => {
  const { path } = useRouteMatch();
  return (
    <DefaultLayout>
      <Switch>
        <Redirect exact from={path} to="/superadmin" />
        <Redirect from="/admin/:page" to="/superadmin/:page" />
      </Switch>
    </DefaultLayout>
  );
};

const Root: React.FC = () => {
  const { coeffUserEmail, coeffUserId } = useSelector(({ app }: AppState) => ({
    coeffUserEmail: app.coeffUserEmail,
    coeffUserId: app.coeffUserId,
    clientFlags: app.clientFlags,
  }));

  const clientFlags = useSelector((state: AppState) => state.app.clientFlags);

  if (!clientFlags) {
    return <TopLevelError />;
  }

  return (
    <StatsigSynchronousProvider
      sdkKey={BuildFlags.statsig_client_key}
      options={{
        environment: { tier: BuildFlags.statsig_environment },
      }}
      user={fetchStatsigUser(coeffUserId, coeffUserEmail)}
      initializeValues={clientFlags.statsig_client_bootstrap_values}
    >
      <Switch>
        <Route exact path="/datasets/create" component={CreateCloudTable} />
        <Route path="/datasets/:cloudTableId" component={CloudTableDetails} />
        <Route path="/web_scraper/auth" component={WebScraperAuthSession} />
        <Route path="/superadmin" component={SuperAdminRoutes} />
        <Route path="/coeffadmin" component={CoeffAdminRoutes} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/admin" component={DomainAdmin_OLD} />
        <Route path="/upgrade" component={Billing} />
        <Route path="/request-looker">
          <Route path="/request-looker/start" component={RequestLookerSetup} />
          <Route path="/request-looker/complete" component={CompleteLookerSetup} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </StatsigSynchronousProvider>
  );
};

export default Root;
