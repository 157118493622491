import React, { useEffect } from "react";

import { Button } from "../components/Button";
import { CenterAlign } from "../components/CenterAlign";
import { FlexColumn } from "../components/FlexLayout";
import { Paper, PaperContent } from "../components/Paper";
import { Typography } from "../components/Typography";
import { sentryCapture, useAuthContext } from "../utils";

export const TopLevelError: React.FC = () => {
  const { logout } = useAuthContext();

  useEffect(() => {
    sentryCapture({ error: new Error("Top level error shown") });
  });

  return (
    <CenterAlign>
      <Paper>
        <PaperContent>
          <FlexColumn style={{ textAlign: "center", alignItems: "center" }}>
            <div>
              <Typography variant="body1" fontWeight={600} gutterBottom={0.5}>
                Unable to launch Coefficient.
              </Typography>

              <Typography color="textSecondary" variant="body2" fontWeight={600} gutterBottom={0.5}>
                Please try again or{" "}
                <a href="mailto:support@coefficient.io" target="_blank">
                  contact support
                </a>{" "}
                if the issue persists.
              </Typography>
            </div>

            <div>
              <Button type="primary" onClick={() => logout()}>
                Go Back
              </Button>
            </div>
          </FlexColumn>
        </PaperContent>
      </Paper>
    </CenterAlign>
  );
};
