import { AutoComplete } from "antd";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

import { Tooltip } from "./Tooltip";

const StyledTagsInput = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 32px;
  width: 100%;
  padding: 8px;
  border: 2px solid ${COLORS.black25};
  border-radius: 6px;
  background: ${COLORS.white};

  &:focus-within {
    border-color: ${COLORS.coeblue4};
  }

  &.error {
    border-color: ${COLORS.red}!important;
  }

  input {
    flex: 1;
    border: none;
    font-size: 14px;
    padding: 0;
    padding-left: 8px;

    ::placeholder,
    ::-webkit-input-placeholder {
      opacity: 0.4;
    }

    &:focus {
      outline: transparent;
    }

    &.padded {
      padding-top: 5px;
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;

    .tag {
      width: auto;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${COLORS.black85};
      padding: 4px 8px;
      font-size: 14px;
      list-style: none;
      border-radius: 6px;
      margin: 0 4px 4px 0;
      background: ${COLORS.coeblue};

      .tag-title {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .tag-close-icon {
        display: block;
        width: 8px;
        text-align: center;
        font-size: 18px;
        margin-left: 8px;
        color: ${COLORS.black85};
        border-radius: 50%;
        cursor: pointer;
      }

      &.tag-error {
        background: ${COLORS.redBg};

        .tag-title {
          text-overflow: ellipsis;
          overflow: hidden;
          color: ${COLORS.red};
        }
      }
    }
  }
`;

const ErrorText = styled.span`
  color: ${COLORS.red};
  font-weight: 500;
`;

const StyledInput = styled.input`
  width: 242px;
  height: 32px;
  border: 2px solid ${COLORS.black25};
  border-radius: 6px;
  background: ${COLORS.white};
  padding-left: 8px;
  color: ${COLORS.black85};

  &:focus-within {
    border-color: ${COLORS.coeblue4};
  }
`;

type Props = {
  error?: boolean;
  placeholder?: string;
  tags?: string[];
  max?: number;
  onChange?: (tags: Array<string>) => void;
  onSearch?: (value: string) => void;
  validator?: (str: string) => string | undefined;
  testid?: string;
  autofocus?: boolean;
  options?: { value: string }[];
  validatorErrorText?: string;
};

const splitTextByRegex = (str: string, regex: RegExp): string[] => str.split(regex);

export const AutoCompleteTagsInput: React.FC<Props> = ({
  validator,
  max,
  tags: _tags,
  placeholder,
  onChange,
  testid,
  autofocus,
  onSearch,
  options,
  validatorErrorText,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [tags, setTags] = useState<string[]>(_tags || []);

  const [typedText, setTypedText] = useState<string>("");

  const [errorText, setErrorText] = useState<string>("");

  const [tagErrors, setTagErrors] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    addTags({});
  }, []);

  const isValid = (text: string) => {
    return validator ? validator(text) === undefined : true;
  };

  const removeTag = (indexToRemove: number) => {
    const newTags = [...tags.filter((_, index) => index !== indexToRemove)];

    setTags(newTags);

    const validatedInputs = newTags.filter(isValid);

    if (validatedInputs.length === newTags.length) {
      setErrorText("");
    }

    onChange?.(newTags);
  };

  const addTags = (params: { selectedValue?: string; split?: boolean }) => {
    const { selectedValue, split = false } = params;
    let inputs: string[] = [];
    if (selectedValue) {
      if (split) {
        inputs = splitTextByRegex(selectedValue, /[, \n\t;]+/);
      } else {
        inputs.push(selectedValue);
      }
    } else {
      inputs = splitTextByRegex(typedText, /[, \n\t;]+/);
    }

    const tagErrors: Map<string, string> = new Map();

    let newTags = Array.from(new Set([...tags, ...inputs]))
      .map(tag => tag.trim())
      .filter(tag => tag.length);

    if (max && newTags.length > max) {
      setErrorText(`You can only send invitations to ${max} users at a time.`);

      newTags = newTags.slice(0, max);
    }

    const validatedInputs = newTags.filter(isValid);

    if (validatedInputs.length !== newTags.length) {
      setErrorText(
        validatorErrorText
          ? validatorErrorText
          : "One or more emails you entered are invalid. Hover over each invalid email for more details."
      );
    }

    newTags.forEach(tag => {
      const error = validator?.(tag);

      if (error) {
        tagErrors.set(tag, error);
      }
    });

    setTagErrors(tagErrors);

    setTags(newTags);

    setTypedText("");

    onChange?.(newTags);
  };

  return (
    <>
      <StyledTagsInput
        id="tags-input-container"
        data-testid={testid}
        className={errorText === "" ? "" : "error"}
        autoFocus={autofocus}
        onClick={() => inputRef.current?.focus()}
        onFocus={() => inputRef.current?.focus()}
      >
        <ul className="coeff__taginput tags">
          {tags.map((tag, index) => (
            <Tooltip key={index} title={tagErrors.get(tag) ? tagErrors?.get(tag) : tag}>
              <li
                key={index}
                className={clsx("tag", {
                  "tag-error": tagErrors?.get(tag),
                })}
              >
                <span className="tag-title">{tag}</span>

                <span className="tag-close-icon" onClick={() => removeTag(index)}>
                  &times;
                </span>
              </li>
            </Tooltip>
          ))}

          <AutoComplete
            autoFocus={autofocus ?? true}
            onKeyDown={event => {
              if (event.key === "Enter" || event.key === "," || event.key === " ") {
                event.preventDefault();
                event.stopPropagation();
                addTags({});
              }
            }}
            dropdownMatchSelectWidth={false}
            onSelect={value => {
              addTags({ selectedValue: value });
            }}
            placeholder={placeholder}
            onChange={e => setTypedText(e)}
            value={typedText}
            onBlur={e => addTags({})}
            onSearch={onSearch}
            options={options}
            className={tags.length > 0 ? "padded" : ""}
          >
            <StyledInput
              ref={inputRef}
              autoFocus={autofocus ?? true}
              onPasteCapture={e => {
                e.preventDefault();
                const data = e.clipboardData.getData("Text");

                addTags({ selectedValue: typedText + data, split: true });
              }}
            />
          </AutoComplete>
        </ul>
      </StyledTagsInput>

      {errorText && <ErrorText>{errorText}</ErrorText>}
    </>
  );
};
