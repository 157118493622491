import { Menu as MenuAD } from "antd";
import styled from "styled-components";

import { COLORS } from "../constants";

export const TableMenu = styled(MenuAD)`
  border: none;

  .ant-menu-item {
    margin: 0px !important;

    height: 32px;
    line-height: 32px;

    &:hover {
      color: ${COLORS.coeblue4};
    }
  }
`;

export const TableMenuItem = MenuAD.Item;
