import {
  DataImportRun,
  Alert,
  WritebackRun,
  DataSourceType,
  SubscriptionPlanType,
  DashboardSpreadsheet,
  GetDataImports200Response,
  GetWritebacks200Response,
  GetAlerts200Response,
} from "@coeff/api";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";

import {
  AppThunkDispatch,
  downloadRunHistory,
  getAlertsByFileId,
  getDataImportRunHistory,
  getDataImportsByFileId,
  getSpreadsheets,
  getTeamActivity,
  getWritebacksByFileId,
  getWritebacksRunHistory,
  updateAlert,
} from "../../actions";
import { AppState } from "../../store";
import { Billing } from "../Billing/Billing";
import { ConfigureSSO } from "../ConfigureSSO";
import { LookerOAuthManageSettings } from "../LookerOAuthManageSettings";
import { LookerOAuthSettings } from "../LookerOAuthSettings";
import { NetSuiteOAuthManageSettings } from "../NetSuiteOAuthManageSettings";
import { NetSuiteOAuthSettings } from "../NetSuiteOAuthSettings";
import { OAuthConfigurations } from "../OAuthConfigurations";
import { Settings } from "../Settings";
import { SnowflakeOAuthManageSettings } from "../SnowflakeOAuthManageSettings";
import { SnowflakeOAuthSettings } from "../SnowflakeOAuthSettings";
import { WorkspaceAdmin } from "../WorkspaceAdmin/WorkspaceAdmin";

import { Sidebar } from "./components";
import { Activity, Spreadsheets } from "./routes";
import { CloudTables } from "./routes/CloudTables";
import { InviteCoworkers } from "./routes/InviteCoworkers";

const Layout = styled.div`
  display: flex;
  height: 100%;
`;

const Content = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  margin-left: 240px;
  min-width: 0;
`;

interface DContext {
  fetchingSheets: boolean;
  planType?: SubscriptionPlanType;
  getAlerts: (targetGoogleSheetsFileId: string) => Promise<GetAlerts200Response>;
  getDataImports: (targetGoogleSheetsFileId: string) => Promise<GetDataImports200Response>;
  getSpreadsheets: () => void;
  getWritebacks: (targetGoogleSheetsFileId: string) => Promise<GetWritebacks200Response>;
  downloadRunHistory: (writebackId: string) => void;
  getWritebacksRunHistory: (
    writebackId: string,
    offset: number
  ) => Promise<{
    writeback_runs: WritebackRun[];
  }>;
  getTeamActivity: () => void;
  getDataImportRunHistory: (
    dataImportId: string,
    offset: number
  ) => Promise<{ data_import_runs: DataImportRun[] }>;
  dataSourcesBySpreadsheet?: object;
  dataSourcesUsed?: string[];
  spreadsheets?: DashboardSpreadsheet[];
  totalRowsImported?: number;
  coeffUserEmail?: string;
  updateAlert: (alert: Alert) => void;
  writebackDisabledForUser?: boolean;
  isGsuiteDomainAdmin?: boolean;
  isCoeffAdmin?: boolean;
}

type ConnectDispatchProps = {
  getAlerts: (targetGoogleSheetsFileId: string) => Promise<GetAlerts200Response>;
  getDataImports: (targetGoogleSheetsFileId: string) => Promise<GetDataImports200Response>;
  getSpreadsheets: () => Promise<{
    spreadsheets: Array<DashboardSpreadsheet>;
    total_rows_imported: number;
  }>;
  getWritebacks: (targetGoogleSheetsFileId: string) => Promise<GetWritebacks200Response>;
  downloadRunHistory: (writebackId: string) => void;
  getWritebacksRunHistory: (
    writebackId: string,
    offset: number
  ) => Promise<{
    writeback_runs: WritebackRun[];
  }>;
  getTeamActivity: () => void;
  getDataImportRunHistory: (
    dataImportId: string,
    offset: number
  ) => Promise<{ data_import_runs: DataImportRun[] }>;
  updateAlert: (alert: Alert) => void;
};

type ConnectProps = {
  planType?: SubscriptionPlanType;
  coeffUserEmail?: string;
  writebackDisabledForUser?: boolean;
  isGsuiteDomainAdmin?: boolean;
  isCoeffAdmin?: boolean;
};

type Props = ConnectProps & ConnectDispatchProps & RouteComponentProps;

type State = {
  dataSourcesBySpreadsheet?: object;
  dataSourcesUsed?: string[];
  fetchingSheets: boolean;
  spreadsheets?: Array<any>;
  totalRowsImported: number;
};

const DashboardContext = React.createContext<DContext | null>(null);

export const useDashboardContext = () => useContext(DashboardContext) as DContext;

class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dataSourcesBySpreadsheet: {},
      dataSourcesUsed: [],
      fetchingSheets: true,
      spreadsheets: [],
      totalRowsImported: 0,
    };
  }

  componentDidMount = () => {
    this.init();
  };

  init = async () => {
    try {
      const { spreadsheets = [], total_rows_imported } = await this.props.getSpreadsheets();

      const { dataSourcesBySpreadsheet, dataSourcesUsed } = spreadsheets.reduce(
        (arr, sheet) => {
          const sheetDataSourcesUsed = [
            ...new Set(sheet.data_imports.map(di => di.data_source_type)),
          ];

          const uniqueDataSources = [...new Set([...arr.dataSourcesUsed, ...sheetDataSourcesUsed])];

          arr.dataSourcesBySpreadsheet[sheet.google_sheets_file_id] = sheetDataSourcesUsed;
          arr.dataSourcesUsed = uniqueDataSources;

          return arr;
        },
        { dataSourcesBySpreadsheet: {}, dataSourcesUsed: [] } as {
          dataSourcesBySpreadsheet: object;
          dataSourcesUsed: DataSourceType[];
        }
      );

      this.setState({
        dataSourcesBySpreadsheet,
        dataSourcesUsed,
        spreadsheets,
        totalRowsImported: total_rows_imported,
        fetchingSheets: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        fetchingSheets: false,
      });
    }
  };

  render = () => {
    const path = this.props.match.path;

    return (
      <DashboardContext.Provider
        value={{
          ...this.state,
          ...this.props,
          planType: this.props.planType,
          fetchingSheets: this.state.fetchingSheets,
          getAlerts: this.props.getAlerts,
          getDataImports: this.props.getDataImports,
          getTeamActivity: this.props.getTeamActivity,
          getSpreadsheets: this.props.getSpreadsheets,
          getWritebacks: this.props.getWritebacks,
          getDataImportRunHistory: this.props.getDataImportRunHistory,
          getWritebacksRunHistory: this.props.getWritebacksRunHistory,
          coeffUserEmail: this.props.coeffUserEmail,
          downloadRunHistory: this.props.downloadRunHistory,
          updateAlert: this.props.updateAlert,
          writebackDisabledForUser: this.props.writebackDisabledForUser,
          isGsuiteDomainAdmin: this.props.isGsuiteDomainAdmin,
          isCoeffAdmin: this.props.isCoeffAdmin,
        }}
      >
        <Layout>
          <Sidebar />

          <Content>
            <Switch>
              <Route exact path={path}>
                <Redirect to={`${path}/spreadsheets`} />
              </Route>
              <Route path={`${path}/spreadsheets`} component={Spreadsheets} />
              <Route exact path={`${path}/datasets`} component={CloudTables} />
              <Route path={`${path}/activity`} component={Activity} />
              <Route path={`${path}/admin`} component={WorkspaceAdmin} />
              <Route path={`${path}/billing`} component={Billing} />
              <Route path={`${path}/settings/oauth-settings`} component={OAuthConfigurations} />
              <Route path={`${path}/settings/looker-oauth`} component={LookerOAuthSettings} />
              <Route
                path={`${path}/settings/manage-looker-oauth`}
                component={LookerOAuthManageSettings}
              />
              <Route
                path={`${path}/settings/snowflake-oauth/:accountId`}
                component={SnowflakeOAuthManageSettings}
              />
              <Route path={`${path}/settings/snowflake-oauth`} component={SnowflakeOAuthSettings} />
              <Route
                path={`${path}/settings/netsuite-oauth/:accountId`}
                component={NetSuiteOAuthManageSettings}
              />
              <Route path={`${path}/settings/netsuite-oauth`} component={NetSuiteOAuthSettings} />
              <Route path={`${path}/settings/configure-sso`} component={ConfigureSSO} />
              <Route path={`${path}/settings`} component={Settings} />
              <Route path={`${path}/invite-coworkers/:eventFrom`} component={InviteCoworkers} />
            </Switch>
          </Content>
        </Layout>
      </DashboardContext.Provider>
    );
  };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch): ConnectDispatchProps => ({
  getAlerts: (targetGoogleSheetsFileId: string) =>
    dispatch(getAlertsByFileId(targetGoogleSheetsFileId)),
  getDataImports: (targetGoogleSheetsFileId: string) =>
    dispatch(getDataImportsByFileId(targetGoogleSheetsFileId)),
  getSpreadsheets: () => dispatch(getSpreadsheets()),
  getTeamActivity: () => dispatch(getTeamActivity()),
  getWritebacks: (targetGoogleSheetsFileId: string) =>
    dispatch(getWritebacksByFileId(targetGoogleSheetsFileId)),
  getDataImportRunHistory: (dataImportId: string, offset: number) =>
    dispatch(getDataImportRunHistory(dataImportId, offset)),
  getWritebacksRunHistory: (writebackId: string, offset: number) =>
    dispatch(getWritebacksRunHistory(writebackId, offset)),
  downloadRunHistory: (writebackId: string) => dispatch(downloadRunHistory(writebackId)),
  updateAlert: (alert: Alert) => dispatch(updateAlert(alert)),
});

const mapStateToProps: (state: AppState) => ConnectProps = ({
  app: {
    coeffUserEmail,
    coeffUserId,
    plan_type,
    writeback_disabled_for_user,
    isCoeffAdmin,
    isGsuiteDomainAdmin,
  },
}) => ({
  coeffUserEmail,
  coeffUserId,
  planType: plan_type,
  writebackDisabledForUser: writeback_disabled_for_user,
  isCoeffAdmin: isCoeffAdmin,
  isGsuiteDomainAdmin: isGsuiteDomainAdmin,
});

const route = withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));

export { route as Dashboard };
