import { SubscriptionUpdateType } from "@coeff/api";
import { COLORS } from "@coeff/utils";
import { useMutation } from "@tanstack/react-query";
import { Checkbox, Form } from "antd";
import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";

import {
  BorderedSelect,
  Button,
  FlexColumn,
  FlexRow,
  Input,
  message,
  Modal,
  Option,
  Typography,
} from "../../../components";
import { BContext } from "../../../types";
import { sentryCapture } from "../../../utils";
import { BillingContext } from "../Billing";

import { CODE_TO_COUNTRY } from "./index";

const StyledInput = styled(Input)`
  border: 2px solid ${COLORS.black15};
`;

type BillingAddressForm = {
  firstName: string;
  lastName: string;
  companyName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export const AddressModel: React.FC<{
  onCancel: () => void;
}> = ({ onCancel }) => {
  const { userSubscription, updateSubscription, syncBilling } = useContext(
    BillingContext
  ) as BContext;

  const billingInfo = userSubscription?.subscription_details?.billing_info;

  const { control, watch, handleSubmit, reset, trigger } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      firstName: billingInfo?.first_name || "",
      lastName: billingInfo?.last_name || "",
      companyName: billingInfo?.company || "",
      includeBillingAddress: false,
      country: billingInfo?.country || "US",
      address1: billingInfo?.line1 || "",
      address2: billingInfo?.line2 || "",
      city: billingInfo?.city || "",
      state: billingInfo?.state || "",
      postalCode: billingInfo?.zip || "",
    },
  });
  const includeBillingAddress = watch("includeBillingAddress");

  const { mutate: updateBillingAddress, isLoading } = useMutation({
    mutationFn: async (data: BillingAddressForm) => {
      const updatedInfo = await updateSubscription({
        subscription_id: userSubscription?.subscription_details?.subscription_id,
        update_type: SubscriptionUpdateType.UpdateBillingAddress,
        billing_info: {
          first_name: data.firstName,
          last_name: data.lastName,
          company: data.companyName,
          line1: data.address1,
          line2: data.address2,
          city: data.city,
          state: data.state,
          zip: data.postalCode,
          country: data.country,
        },
      });

      if (updatedInfo.is_success) {
        await syncBilling();
      }

      return updatedInfo;
    },
    onSuccess: response => {
      if (response.is_success) {
        message.success("Billing info updated successfully");
        onCancel();
        reset();
      } else {
        message.error(`Failed to update billing info: ${response.error_msg}`);
      }
    },
    onError: (error: Error) => {
      sentryCapture({ error, name: "UpdateBillingAddress" });
      message.error(`Failed to update billing info: ${error.message}`);
    },
  });

  const handleCancel = () => {
    reset(); // Reset form when modal is closed
    onCancel();
  };

  // Watch fields for dynamic validation
  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const companyName = watch("companyName");

  return (
    <Modal visible onCancel={handleCancel} width={600}>
      <Typography fontWeight={"bold"} fontSize="22px">
        Billed to
      </Typography>
      <Typography color="textSecondary">This information will appear on your invoices </Typography>
      <Form
        layout="vertical"
        style={{ padding: "16px 0" }}
        autoComplete="off"
        onFinish={handleSubmit(data => updateBillingAddress(data))}
      >
        <FlexColumn>
          <FlexRow>
            <FlexColumn gap={0} style={{ flex: 1 }}>
              <Typography>First Name</Typography>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  validate: value => {
                    if (!value && !companyName) {
                      return "Either First & Last name or Company name is required";
                    }
                    if (value && !lastName) {
                      return "Last name is required when First name is provided";
                    }
                    if (value) {
                      if (!/^[A-Za-z\s-']+$/.test(value)) {
                        return "First name can only contain letters, spaces, hyphens and apostrophes";
                      }
                      if (value.length < 2) {
                        return "First name must be at least 2 characters";
                      }
                      if (value.length > 50) {
                        return "First name cannot exceed 50 characters";
                      }
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <StyledInput
                    {...field}
                    status={error ? "error" : ""}
                    onChange={e => {
                      field.onChange(e);
                      // Trigger validation for dependent fields
                      trigger(["lastName", "companyName"]);
                    }}
                  />
                )}
              />
            </FlexColumn>
            <FlexColumn gap={0} style={{ flex: 1 }}>
              <Typography>Last Name</Typography>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  validate: value => {
                    if (firstName && !value) {
                      return "Last name is required when First name is provided";
                    }
                    if (value) {
                      if (!/^[A-Za-z\s-']+$/.test(value)) {
                        return "Last name can only contain letters, spaces, hyphens and apostrophes";
                      }
                      if (value.length < 2) {
                        return "Last name must be at least 2 characters";
                      }
                      if (value.length > 50) {
                        return "Last name cannot exceed 50 characters";
                      }
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <StyledInput
                    {...field}
                    status={error ? "error" : ""}
                    onChange={e => {
                      field.onChange(e);
                      // Trigger validation for dependent fields
                      trigger(["firstName", "companyName"]);
                    }}
                  />
                )}
              />
            </FlexColumn>
          </FlexRow>

          <FlexColumn gap={0}>
            <Typography>Company name</Typography>
            <Controller
              name="companyName"
              control={control}
              rules={{
                validate: value => {
                  if (!value && (!firstName || !lastName)) {
                    return "Either Company name or First & Last name is required";
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <StyledInput
                  {...field}
                  onChange={e => {
                    field.onChange(e);
                    // Trigger validation for dependent fields
                    trigger(["firstName", "lastName"]);
                  }}
                />
              )}
            />
          </FlexColumn>

          <Controller
            name="includeBillingAddress"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox checked={value} onChange={e => onChange(e.target.checked)}>
                <Typography fontWeight={"bold"}>
                  Include billing address on invoices (optional)
                </Typography>
              </Checkbox>
            )}
          />

          {includeBillingAddress && (
            <>
              <Typography fontWeight={"bold"} fontSize="18">
                Billing Address
              </Typography>
              <FlexColumn gap={0}>
                <Typography>Country</Typography>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <BorderedSelect {...field} style={{ width: "100%" }}>
                      {Object.entries(CODE_TO_COUNTRY).map(([code, name]) => (
                        <Option key={code} value={code}>
                          {name}
                        </Option>
                      ))}
                    </BorderedSelect>
                  )}
                />
              </FlexColumn>
              <FlexColumn gap={0}>
                <Typography>Address 1</Typography>
                <Controller
                  name="address1"
                  control={control}
                  render={({ field }) => <StyledInput {...field} placeholder="Optional" />}
                />
              </FlexColumn>
              <FlexColumn gap={0}>
                <Typography>Address 2</Typography>
                <Controller
                  name="address2"
                  control={control}
                  render={({ field }) => <StyledInput {...field} placeholder="Optional" />}
                />
              </FlexColumn>
              <FlexColumn gap={0}>
                <Typography>City</Typography>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => <StyledInput {...field} placeholder="Optional" />}
                />
              </FlexColumn>
              <FlexRow>
                <FlexColumn gap={0} style={{ flex: 1 }}>
                  <Typography>State</Typography>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => <StyledInput {...field} placeholder="Optional" />}
                  />
                </FlexColumn>
                <FlexColumn gap={0} style={{ flex: 1 }}>
                  <Typography>Postal/Zip code</Typography>
                  <Controller
                    name="postalCode"
                    control={control}
                    render={({ field }) => <StyledInput {...field} placeholder="Optional" />}
                  />
                </FlexColumn>
              </FlexRow>
            </>
          )}
        </FlexColumn>
      </Form>
      <FlexRow style={{ justifyContent: "flex-end" }}>
        <Button onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(data => updateBillingAddress(data))}
          disabled={isLoading}
        >
          {isLoading ? "Saving..." : "Save"}
        </Button>
      </FlexRow>
    </Modal>
  );
};
