import { DataImportSchedule } from "@coeff/api";
import React from "react";

import { WEEKDAYS } from "../../constants";
import { getTZAdjustedHour, hourToTime } from "../../utils";

type Props = {
  schedule: DataImportSchedule;
};

export const ScheduleText: React.FC<Props> = ({ schedule }) => {
  const getHourlyText = () => {
    const interval = schedule.every_n_hours ?? 1;

    if (interval > 1) {
      return `Every ${interval} hours`;
    }

    return "Every hour";
  };

  return (
    <div>
      {schedule.repeat_type === "hourly" && <span>{getHourlyText()}</span>}

      {schedule.repeat_type === "daily" && <span>Daily</span>}

      {schedule.repeat_type === "weekly" && schedule.days_of_week?.length === 1 && (
        <span>{WEEKDAYS.find(day => day.dataKey === schedule.days_of_week?.[0])?.label}</span>
      )}

      {schedule.repeat_type === "weekly" && schedule.days_of_week?.length !== 1 && (
        <span>
          {WEEKDAYS.filter(day => schedule.days_of_week?.includes(day.dataKey))
            .map(day => day.shortLabel)
            .join(", ")}
        </span>
      )}

      {schedule.repeat_type !== "hourly" && (
        <>
          <span> at </span>
          <span>{hourToTime(getTZAdjustedHour(schedule))}</span>
        </>
      )}
    </div>
  );
};
