import { DataSourceType } from "@coeff/api";
import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Header } from "../routes/Dashboard/components/Header";

import { Breadcrumb, BreadcrumbItem } from "./Breadcrumb";
import { Button } from "./Button";
import { WheelIcon } from "./Icons";
import { ImportTypeIcon } from "./ImportTypeIcon";

const Container = styled.div<{ $width: "wide" | "narrow" }>`
  padding: 20px 30px;
  max-width: ${props => (props.$width === "wide" ? "960px" : "660px")};
`;

const StyledHeader = styled(Header)`
  h1 {
    margin: 0 10px;
  }

  .helpGuide {
    display: flex;
    align-items: center;
    margin-left: auto !important;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  > p {
    margin: 0;
  }
`;

type HelpGuideProps = {
  helpGuideLink: string;
};

const HelpGuide: React.FC<HelpGuideProps> = ({ helpGuideLink }) => {
  return (
    <div className="helpGuide">
      <WheelIcon></WheelIcon>
      <Button type="link" noPadding>
        <a href={helpGuideLink} target="_blank">
          Help Guide
        </a>
      </Button>
    </div>
  );
};

const HELP_GUIDE_LINKS = {
  netsuite: "https://help.coefficient.io/hc/en-us/articles/23948673743259-NetSuite",
  snowflake: "https://help.coefficient.io/hc/en-us/articles/18180164081947-Snowflake",
  looker: "https://help.coefficient.io/hc/en-us/articles/17969072042907-Looker",
};

function SettingsLayout({
  children,
  title,
  dataSourceType,
  width = "wide",
  hideBreadcrumb,
}: {
  children: React.ReactNode;
  title: string;
  dataSourceType: DataSourceType;
  width?: "wide" | "narrow";
  hideBreadcrumb?: boolean;
}) {
  const helpGuideLink = HELP_GUIDE_LINKS[dataSourceType];
  return (
    <Container $width={width}>
      {!Boolean(hideBreadcrumb) && (
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={{ pathname: `/dashboard/admin` }}>Admin</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={{ pathname: `/dashboard/admin/settings` }}>Settings</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={{ pathname: `/dashboard/settings/oauth-settings` }}>OAuth Settings</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      )}
      <StyledHeader>
        <ImportTypeIcon type={dataSourceType} />
        <h1>{title}</h1>
        {helpGuideLink && <HelpGuide helpGuideLink={helpGuideLink} />}
      </StyledHeader>
      <Content>{children}</Content>
    </Container>
  );
}

export { SettingsLayout };
