import { AssetsHost } from "@coeff/utils";
import React, { useState } from "react";
import styled from "styled-components";

import { COLORS } from "../constants";
import { COEFF_IMAGE_STORE_BASE_URL } from "../utils";

import { ConfirmBox } from "./ConfirmBox";
import { BulletCheck, Lock } from "./Icons";
import { BulletList } from "./misc";

const Wrap = styled.div`
  padding: 16px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      font-size: 12px;
    }

    svg {
      margin-right: 8px;
    }
  }

  .learnMoreLink {
    text-decoration: underline;
    text-decoration-style: dashed;
    cursor: pointer;
  }
`;

const LearnMoreModal = styled.div`
  color: ${COLORS.black85};
  padding-top: 16px;

  .center {
    text-align: center;
    line-height: 20px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
  }

  img:last-child {
    padding-top: 18px;
  }
`;

const StyledBulletList = styled(BulletList)`
  li {
    font-weight: 500;
  }
`;

const StyledConfirmBox = styled(ConfirmBox)`
  .ant-modal-footer {
    .submit {
      width: 100%;
    }
  }
`;

export const SecurityInfo = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Wrap>
      <div>
        <Lock fill={COLORS.black85} />
        <div>
          Coefficient encrypts your credentials and never stores your source data.{" "}
          <span className="learnMoreLink" onClick={() => setShowModal(true)}>
            Learn more
          </span>
        </div>
      </div>

      <StyledConfirmBox
        closable={true}
        visible={showModal}
        onOk={() => setShowModal(false)}
        showCancel={false}
        onCancel={() => setShowModal(false)}
        okText="Got it"
        className="flex-end"
      >
        <LearnMoreModal>
          <div className="center">
            <img src={`${AssetsHost()}/data-safe.svg`} />

            <div className="title">
              Keeping your data
              <br /> secure is our #1 priority
            </div>

            <img src={`${COEFF_IMAGE_STORE_BASE_URL}/soc-2-blue.png`} width="58" height="auto" />
          </div>

          <StyledBulletList>
            <li>
              <BulletCheck />
              <div>We are SOC II compliant.</div>
            </li>

            <li>
              <BulletCheck />
              <div>We never store your source data when importing.</div>
            </li>

            <li>
              <BulletCheck />
              <div>
                We encrypt all of your credentials end-to-end, including OAuth tokens, API keys, and
                passwords.
              </div>
            </li>

            <li>
              <BulletCheck />
              <div>
                We perform routine penetration and vulnerability tests against our application and
                cloud infrastructure.
              </div>
            </li>
          </StyledBulletList>
        </LearnMoreModal>
      </StyledConfirmBox>
    </Wrap>
  );
};
