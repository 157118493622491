import { DefaultApi, GetBillingDetailsRequest, SendCoworkerInvitationRequest } from "@coeff/api";
import { User } from "@coeff/api-admin-ext";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SortColumn, SortDirection } from "../actions";
import { API_EXT_ADMIN_ROUTES } from "../constants";
import { ExtAdminDomainUser } from "../types";
import { useApiContext } from "../utils";

import { DEPRECATED_ApiManager } from "./DEPRECATED_ApiManager";
import { QueryCacheKey } from "./queryClient";

export const fetchDomainUsers = async (api: DefaultApi) => {
  const response = await api.getDomainUsers();
  return response.data;
};

export const useDomainUsers = () => {
  const { apiClient: api } = useApiContext();

  return useQuery([QueryCacheKey.DOMAIN_USERS], () => fetchDomainUsers(api), {});
};

export type FetchAdminDomainUsersParams = {
  domain?: string;
  pageSize?: number;
  offset: number;
  sortColumn?: SortColumn;
  filters?: string;
};

export const fetchAdminDomainUsers = async (
  deprecatedAPI: DEPRECATED_ApiManager,
  params: FetchAdminDomainUsersParams = {
    pageSize: 100,
    offset: 0,
    sortColumn: {
      columnKey: "created_dt",
      value: SortDirection.descend,
    },
  }
): Promise<{ domainUsers: ExtAdminDomainUser[]; totalRecords: number }> => {
  if (params.domain) {
    deprecatedAPI.setDomainParam(params.domain);
  }

  const query = new URLSearchParams();
  if (params.pageSize) {
    query.set("page_size", String(params.pageSize));
  }
  if (params.offset) {
    query.set("offset", String(params.offset));
  }
  if (params.sortColumn) {
    query.set("sort_by", params.sortColumn.columnKey);
    query.set("sort_order", params.sortColumn.value);
  }
  const { users: domainUsers, total_records: totalRecords } = await deprecatedAPI.request({
    method: "GET",
    url: `${API_EXT_ADMIN_ROUTES.users}?${query.toString()}`,
  });

  return { domainUsers, totalRecords };
};

export const useAdminDomainUsers = (
  params: FetchAdminDomainUsersParams,
  { enabled }: UseQueryOptions = {}
) => {
  const { DEPRECATED_api } = useApiContext();

  return useQuery(
    [QueryCacheKey.DOMAIN_USERS, params],
    () => fetchAdminDomainUsers(DEPRECATED_api, params),
    {
      enabled,
    }
  );
};

export const fetchInvitations = async (api: DefaultApi, domain: string) => {
  const response = await api.getInvitations({ domain });
  return response.data;
};

export const useInvitations = (domain: string) => {
  const { apiClient: api } = useApiContext();
  return useQuery([QueryCacheKey.DOMAIN_INVITATIONS], () => fetchInvitations(api, domain), {});
};

export const useBillingDetails = (
  request: GetBillingDetailsRequest,
  { enabled }: UseQueryOptions = {}
) => {
  const { apiClient: api } = useApiContext();
  return useQuery(
    [
      QueryCacheKey.BILLING_DETAILS,
      request.temporary_front_end_estimated_amount,
      request.billing_interval,
      request.num_users,
      request.plan_type,
      request.num_data_sources,
    ],
    () => getBillingDetails(api, request),
    { enabled }
  );
};
export const getBillingDetails = async (
  api: DefaultApi,
  get_billing_details_request: GetBillingDetailsRequest
) => {
  const response = await api.getBillingDetails({ get_billing_details_request });
  return response.data;
};

export const useSendCoworkerInvitation = () => {
  const { apiClient: api } = useApiContext();

  const mutator = async (send_coworker_invitation_request: SendCoworkerInvitationRequest) => {
    const response = await api.sendCoworkerInvitation({ send_coworker_invitation_request });
    return response.data;
  };
  return useMutation(mutator);
};
