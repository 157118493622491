import {
  SubscriptionBillingInterval,
  SubscriptionPlanType,
  UserSubscriptionInfo,
} from "@coeff/api";
import moment from "moment";
import querystring from "query-string";

import { FREE_DOMAINS } from "../constants";
import { PricingSummary as PricingSummaryResponseType } from "../types";

export const emailFreeTrial = (userEmail: string) => {
  const params = querystring.stringify({
    view: "cm",
    to: "upgrade@coefficient.io",
    su: `Coefficient upgrade plan (${userEmail})`,
  });
  window.open(`https://mail.google.com/mail/u/0/?${params}`, "_blank");
};

export const planEmail = (plan: string, term: string) => {
  const params = querystring.stringify({
    view: "cm",
    to: "upgrade@coefficient.io",
    su: `Coefficient upgrade plan to ${plan}`,
    body: `Hello,\n\nPlease send me details for this plan: ${plan} (${term})\n# of users:\n# of data sources:`,
  });
  window.open(`https://mail.google.com/mail/u/0/?${params}`, "_blank");
};

export const getIsGmailLikeDomainFromEmail = (email: string) => {
  const split = email.split("@");

  const domain = split[1];

  return domain !== undefined && FREE_DOMAINS.has(domain);
};

export const pluralize = (count: number | undefined) => {
  return count === 1 ? "" : "s";
};

export const pluralizeString = (str: string, count: number | undefined) => {
  return `${count} ${str}${count === 1 ? "" : "s"}`;
};

export const planNameFromType = (plan: string | undefined): string => {
  if (!plan || plan === "") {
    return "";
  }

  if (
    plan === SubscriptionPlanType.LifetimeFree ||
    plan === SubscriptionPlanType.FreeTrial ||
    plan === SubscriptionPlanType.LifetimeFree
  ) {
    return "Free";
  }
  return plan
    .split("_")
    .map(p => p.charAt(0).toUpperCase() + p.slice(1))
    .join(" ");
};

export const getPricingSummary = (
  planType: SubscriptionPlanType | undefined,
  billingInterval: SubscriptionBillingInterval,
  monthlyPrice: number,
  annualMonthlyPrice: number,
  additionalDataSouceCost: number,
  usersCount: number,
  dataSourcesCount: number,
  discountPercentage?: number
): PricingSummaryResponseType => {
  const oneUserCost = (billingInterval === "annually" ? annualMonthlyPrice : monthlyPrice) * 1;

  const nonDiscountedOneUserCost = monthlyPrice;
  const usersCost =
    (billingInterval === "annually" ? annualMonthlyPrice : monthlyPrice) * (usersCount || 1);

  const oneDataSourceCost = (() => {
    if (dataSourcesCount <= 1) {
      return 0;
    }
    return additionalDataSouceCost * (usersCount || 1);
  })();

  const dataSourceCost = oneDataSourceCost * (dataSourcesCount - 1);

  let totalMonthlyCost = usersCost + dataSourceCost;

  let discountPrice = 0;
  if (discountPercentage) {
    discountPrice = (totalMonthlyCost / 100) * discountPercentage;
  }
  totalMonthlyCost = totalMonthlyCost - discountPrice;

  const dueNowCost = billingInterval === "annually" ? totalMonthlyCost * 12 : totalMonthlyCost;

  const annualDiscount =
    billingInterval === "monthly" ? 0 : (usersCount || 1) * (monthlyPrice - annualMonthlyPrice);

  return {
    usersCost,
    dataSourceCost,
    totalMonthlyCost,
    dueNowCost,
    annualDiscount,
    oneUserCost,
    nonDiscountedOneUserCost,
    oneDataSourceCost,
    discountPrice,
  };
};

export const getFreeTrialNumFractionalDaysLeft = (
  freeTrialExpirationDt: Date,
  now?: moment.Moment
): number => {
  now = now || moment();
  const expirationDate = moment.utc(freeTrialExpirationDt);
  return Math.max(expirationDate.diff(now, "days", true), 0);
};

export const numberWithCommas = x => {
  return Number(x).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

// Note this method rounds the number to the nearest .00 or .50 value
export const roundToHalf = (num: number): number => {
  const numInt = Math.floor(num);
  const numDecimal = num - Math.floor(num);
  const roundedDecimalAsInt = Math.round((numDecimal * 10) / 5) * 5;
  const roundedDecimal = roundedDecimalAsInt / 10;

  return numInt + roundedDecimal;
};

function getIsUninstalledUser(users: UserSubscriptionInfo): boolean {
  let lastActive: moment.Moment | undefined;

  const lastClock = users.user.last_clock_trigger_dt
    ? moment(users.user.last_clock_trigger_dt)
    : undefined;
  const lastSession = users.user.last_interactive_session_dt
    ? moment(users.user.last_interactive_session_dt)
    : undefined;

  if (lastClock && lastSession) {
    lastActive = moment.max(lastClock, lastSession);
  } else if (lastClock && !lastSession) {
    lastActive = lastClock;
  } else if (lastSession && !lastClock) {
    lastActive = lastSession;
  }

  let isUninstalled = true;

  if (lastActive) {
    // this function is different from _compute_uninstall_dt as it tries to create a stable
    // prediction of if the user has uninstalled coefficient therefore the window of validation
    // is extended from 1 day to 30 days
    isUninstalled = lastActive && lastActive.add(30, "days").isBefore(moment.now());
  }

  return Boolean(isUninstalled);
}

export const getSortedOtherUsersInDomain = (users: Array<UserSubscriptionInfo>) => {
  const sortedOtherUsersInDomain = users
    .sort((a, b) => {
      const bName = `${b.user.first_name} ${b.user.last_name}`;
      const aName = `${a.user.first_name} ${a.user.last_name}`;

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => {
      const aIsManaged = a.managed_by_user !== undefined ? 1 : -1;
      const bIsManaged = b.managed_by_user !== undefined ? 1 : -1;

      if (aIsManaged < bIsManaged) {
        return -1;
      }
      if (aIsManaged > bIsManaged) {
        return 1;
      }
      return 0;
    });

  return sortedOtherUsersInDomain;
};

export const getActiveOtherUsersInDomain = (users: Array<UserSubscriptionInfo>) => {
  const activeOtherUsers = users.filter(u => !getIsUninstalledUser(u));
  const sortedActiveOtherUsersInDomain = getSortedOtherUsersInDomain(activeOtherUsers);

  return sortedActiveOtherUsersInDomain;
};
