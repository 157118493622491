import { CloudTable } from "@coeff/api";
import { AssetsHost } from "@coeff/utils";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import {
  PartialCloudTable,
  QueryCacheKey,
  useCloudTablePreviewData,
  useCreateCloudTableMutation,
  useRunCloudTableMutation,
} from "../../api";
import {
  AlertExclamation,
  Button,
  CenterAlign,
  ChevronLeft,
  DataImportInlineAlert,
  DataImportTable,
  DatasetError,
  EmptyList,
  InlineAlert,
  LoaderWithPerfTimings,
  Paper,
  TableIcon,
  Typography,
  message,
} from "../../components";
import { COLORS, FREE_DOMAINS } from "../../constants";
import { AppState } from "../../store";
import { assertIsDefined, getErrorMessage, getErrorTitle, useTrack } from "../../utils";

import { CreateCloudTableForm } from "./CreateCloudTableForm";

const Layout = styled.div`
  line-height: 1.3;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border-bottom: 1px solid ${COLORS.black6};
`;

const GridContent = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 100%;
  min-height: 0;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const Sidebar = styled.div`
  height: 100%;
  overflow: auto;
  padding: 16px;
`;

const TableContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
`;

const TableFooter = styled.div`
  padding: 8px 16px;
  text-align: center;
`;

const PlaceholderContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.black4};
`;

const Placeholder = styled(CenterAlign)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
`;

const ErrorContainer = styled(Paper)`
  height: 100%;
  width: 100%;
  margin: 0 8px;
`;

const SubmittingOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmittingContent = styled.div`
  background: white;
  border-radius: 50%;
  padding: 60px;
  aspect-ratio: 1/1;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.02);
`;

const SubmittingContentOffset = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 12px;
  margin-top: 0;
`;

export enum CreateCloudTableFormStep {
  SOURCE_INFO,
  METADATA,
  SCHEDULE,
  SHARING,
}

export const CreateCloudTable: React.FC = () => {
  const track = useTrack();

  const history = useHistory();

  const queryClient = useQueryClient();

  const { search } = useLocation();

  const [step, setStep] = React.useState(CreateCloudTableFormStep.SOURCE_INFO);

  const [isSubmitDelayed, setIsSubmitDelayed] = React.useState(false);

  const searchParams = new URLSearchParams(search);

  const [topLevelError, setTopLevelError] = React.useState<unknown>();

  const [cloudTable, setCloudTable] = React.useState<PartialCloudTable>({
    is_shared_with_domain: true,
  });

  const {
    data: previewData,
    isFetching: isFetchingPreviewData,
    error: errorPreviewData,
    refetch: refetchPreviewData,
    isRefetching: isRefetchingPreviewData,
  } = useCloudTablePreviewData(
    {
      ...cloudTable,
      cloud_table_name: cloudTable.cloud_table_name ?? "Default",
    } as CloudTable,
    {
      enabled: Boolean(cloudTable.source_info),
    }
  );

  const { mutateAsync: createCloudTable, isLoading: isLoadingCreateCloudTable } =
    useCreateCloudTableMutation();

  const { mutateAsync: runCloudTable, isLoading: isLoadingRunCloudTable } =
    useRunCloudTableMutation();

  const isSubmitting = isLoadingCreateCloudTable || isLoadingRunCloudTable;

  const email = useSelector<AppState, string>(state => state.app.email);

  const domain = email.split("@")[1];

  useEffect(() => {
    document.title = "Create Dataset - Coefficient Workspace";

    track("dataset_create_start", {
      event_from: searchParams.get("from") === "gsheets-addon" ? "list_sidebar" : "list_web",
    });
  }, []);

  useEffect(() => {
    if (!isFetchingPreviewData && isSubmitDelayed) {
      setIsSubmitDelayed(false);
      handleStepSubmit(cloudTable, { isFromDelayedSubmit: true });
    }
  }, [isFetchingPreviewData, isSubmitDelayed]);

  const handleStepSubmit = (
    cloudTable: PartialCloudTable,
    options: { isFromDelayedSubmit: boolean }
  ) => {
    setCloudTable(cloudTable);

    if (options.isFromDelayedSubmit) {
      // If we delayed submit to wait for the preview run and the preview run
      // has an error, don't continue with submitting.
      if (previewData?.cloud_table.last_import_status?.status_level === "error") {
        return;
      }
    } else {
      // If we submit while a preview run is happening, we need to wait until it
      // finishes to decide whether or not we can proceed to the next step. We
      // delay the submit and re-run it once isFetchingPreviewData === false.
      if (isFetchingPreviewData) {
        setIsSubmitDelayed(true);
        return;
      }

      // If there's already an error on screen and the user tries to submit again,
      // manually trigger a refetch and delay submit until it is finished.
      if (previewData?.cloud_table.last_import_status?.status_level === "error") {
        refetchPreviewData();
        setIsSubmitDelayed(true);
        return;
      }
    }

    switch (step) {
      case CreateCloudTableFormStep.SOURCE_INFO: {
        setStep(CreateCloudTableFormStep.METADATA);
        return;
      }
      case CreateCloudTableFormStep.METADATA: {
        setStep(CreateCloudTableFormStep.SCHEDULE);
        return;
      }
      case CreateCloudTableFormStep.SCHEDULE: {
        if (FREE_DOMAINS.has(domain)) {
          handleSubmit(cloudTable as CloudTable);
          return;
        } else {
          setStep(CreateCloudTableFormStep.SHARING);
          return;
        }
      }
      case CreateCloudTableFormStep.SHARING: {
        handleSubmit(cloudTable as CloudTable);
        return;
      }
    }
  };

  const handleSubmit = async (cloudTable: CloudTable) => {
    try {
      const {
        cloud_table: { cloud_table_id },
      } = await createCloudTable(cloudTable);

      assertIsDefined(cloud_table_id);

      // Run this in its own try/catch block. If this fails
      // we still want to redirect to the details page so
      // that the user doesn't create multiple tables by
      // retrying.
      try {
        await runCloudTable(cloud_table_id);
      } catch (error) {
        message.error("Failed to create dataset");
      }

      track("dataset_create_done", { dataset_id: cloud_table_id });

      history.replace(`/datasets/${cloud_table_id}`);
    } catch (error) {
      track("dataset_create_failed");

      message.error("Failed to create dataset");
    }
  };

  const getPlaceholderMessage = (cloudTable: PartialCloudTable) => {
    if (
      cloudTable.source_info?.google_sheets_is_specific_range &&
      !cloudTable.source_info.google_sheets_range
    ) {
      return "Enter a range";
    }

    return "Choose a sheet";
  };

  const RetryButton = () => (
    <Button
      type="link"
      style={{ marginRight: 8, height: "auto" }}
      noPadding
      onClick={() => queryClient.invalidateQueries([QueryCacheKey.CLOUD_TABLE_PREVIEW_DATA])}
    >
      Retry
    </Button>
  );

  return (
    <Layout>
      <Header>
        <Button
          onClick={() => history.push("/dashboard/datasets")}
          type="text"
          icon={<ChevronLeft />}
        ></Button>

        <TableIcon />

        <Typography noWrap variant="h6" title={cloudTable.cloud_table_name} fontWeight={600}>
          New Dataset
        </Typography>
      </Header>

      <GridContent>
        <Sidebar>
          <CreateCloudTableForm
            step={step}
            setStep={setStep}
            cloudTable={cloudTable}
            onChange={cloudTable => setCloudTable(cloudTable)}
            onStepSubmit={cloudTable =>
              handleStepSubmit(cloudTable, { isFromDelayedSubmit: false })
            }
            isSubmitting={isSubmitting}
            isFetchingPreviewData={isFetchingPreviewData}
            setTopLevelError={setTopLevelError}
          />
        </Sidebar>

        <Content>
          {(topLevelError || errorPreviewData) && !isSubmitting ? (
            <ErrorContainer>
              <InlineAlert
                type="error"
                showIcon={true}
                icon={<AlertExclamation type="error" />}
                message={getErrorTitle(topLevelError ?? errorPreviewData, "Error")}
                description={getErrorMessage(
                  topLevelError ?? errorPreviewData,
                  "Failed to load dataset"
                )}
                action={<RetryButton />}
              />

              <CenterAlign>
                <DatasetError />
              </CenterAlign>
            </ErrorContainer>
          ) : (isFetchingPreviewData || isRefetchingPreviewData) && !isSubmitting ? (
            <CenterAlign>
              <LoaderWithPerfTimings name="CreateCloudTable" size="large" />
            </CenterAlign>
          ) : previewData ? (
            <TableContainer style={{ opacity: isSubmitting ? "20%" : "100%" }}>
              <DataImportInlineAlert
                last_import_status={previewData.cloud_table.last_import_status}
                action={<RetryButton />}
              />

              <DataImportTable
                cloudTable={previewData.cloud_table}
                headers={(previewData.preview_columns ?? []).map(
                  column_name =>
                    previewData.cloud_table.columns?.find(
                      column => column.column_name === column_name
                    )?.alias ?? column_name
                )}
                data={previewData.preview_data_rows ?? []}
                offset={0}
              />
              <TableFooter>
                {previewData.preview_data_rows &&
                previewData.total_row_count &&
                previewData.preview_data_rows.length >= 99 ? (
                  <Typography color="textSecondary" align="center" variant="body3">
                    Preview is limited to the first 100 rows
                  </Typography>
                ) : null}
              </TableFooter>
            </TableContainer>
          ) : !isSubmitting ? (
            <PlaceholderContainer>
              <Placeholder>
                <EmptyList />

                <Typography fontWeight={600} variant="h5" color="textTertiary">
                  {getPlaceholderMessage(cloudTable)}
                </Typography>
              </Placeholder>
            </PlaceholderContainer>
          ) : null}

          <SubmittingOverlay style={{ visibility: isSubmitting ? "visible" : "hidden" }}>
            <SubmittingContent>
              <SubmittingContentOffset>
                <video width="100" height="100" autoPlay loop muted>
                  <source src={`${AssetsHost()}/creating-datasets.mp4`} />
                </video>

                <Typography variant="body1" fontWeight={600}>
                  Creating Dataset...
                </Typography>
              </SubmittingContentOffset>
            </SubmittingContent>
          </SubmittingOverlay>
        </Content>
      </GridContent>
    </Layout>
  );
};
