import { DataImportSchedule } from "@coeff/api";
import { Switch as SwitchAD } from "antd";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

import { COLORS, WEEKDAYS } from "../constants";
import { getStringHashCode, getTimeZone, scheduledHourToTime } from "../utils";

import { Tooltip } from "./Tooltip";

import { Card, CardHeader, Date as CalendarIcon } from ".";

const StyledPanel = styled.div`
  padding: 10px;

  & .how-it-works-wrapper {
    margin: 0 auto;
    text-align: center;
  }

  & .how-it-works {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    color: ${COLORS.coeblue4};
    border-bottom: 1px dashed ${COLORS.coeblue4};
  }

  .coeff__card_header {
    .proTag {
      margin-left: 5px;
    }
  }
`;

const Switch = styled(SwitchAD)`
  &.ant-switch-checked {
    background-color: ${COLORS.teal};
  }
`;

const SchedulerPanel = styled.div`
  margin: 0px 0px 0px 28px;

  &.margin-centered {
    margin: 5px 20px;
  }

  .editBtn {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 15px;
    font-size: 13px;
    line-height: 13px;
    background: none;
    border: none;
    color: ${COLORS.black45};
    cursor: pointer;
    outline: 0;
    color: ${COLORS.coeblue4};
    border-bottom: none;
    padding: 0;

    svg {
      height: 14px;
      display: block;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

type SchedulerProps = {
  dataImportId?: string;
  disabled?: boolean;
  disableHeader?: boolean;
  disableRefreshScheduler?: boolean;
  disableSnapshotScheduler?: boolean;
  disabledTooltip?: string;
  removeSnapshotSchedule?: () => void;
  schedule?: DataImportSchedule;
  setSnapshotSchedule?: (arg: DataImportSchedule) => void;
  snapshot_schedule?: DataImportSchedule;
  ss_id: string;
};

export const DEPRECATED_Scheduler = ({
  dataImportId,
  disabled,
  disableHeader,
  disableRefreshScheduler,
  disableSnapshotScheduler,
  disabledTooltip,
  removeSnapshotSchedule,
  schedule,
  setSnapshotSchedule,
  snapshot_schedule,
  ss_id,
}: SchedulerProps) => {
  const [editMode, setEditMode] = useState<boolean>(disableHeader || false);

  const defaultSchedule: DataImportSchedule = {
    repeat_type: "daily",
    hour: 4 + (getStringHashCode(ss_id) % 6),
    days_of_week: [1],
    tz_name: getTimeZone(),
  };

  const currentSchedule: DataImportSchedule = schedule ? { ...schedule } : defaultSchedule;

  // @ts-ignore
  const currentSnapshotSchedule: DataImportSchedule = snapshot_schedule
    ? { ...snapshot_schedule }
    : { tz_name: getTimeZone() };

  const getTZAdjustedHour = (hr: number) => {
    if (
      currentSchedule.tz_name &&
      currentSchedule.hour &&
      currentSchedule.tz_name !== getTimeZone()
    ) {
      const localOffset = moment().utcOffset(); // Local offset

      // The typescript is ignored in the below line as the compiled moment-timezone
      // is added to this page as <script> tag so the types are missing
      // @ts-ignore
      const scheduleOffset = moment.tz(currentSchedule.tz_name).utcOffset();
      const diffInHours = (localOffset - scheduleOffset) / 60;

      return Math.round(currentSchedule.hour + diffInHours + 24) % 24;
    }
    return hr;
  };

  const getHourlyText = () => {
    const interval = currentSchedule.every_n_hours || 1;

    if (interval > 1) {
      return `Every ${interval} hours`;
    }

    return "Every hour";
  };

  return (
    <Card className={schedule ? "green" : ""}>
      <>
        {!disableRefreshScheduler && (
          <StyledPanel>
            {/* top header when not in edit mode */}
            {!disableHeader && (
              <CardHeader icon={<CalendarIcon color={COLORS.teal} />} title="Refresh Schedule">
                <Tooltip title={disabled ? disabledTooltip : ""} placement={"topRight"}>
                  <Switch size="small" checked={!!schedule} disabled={disabled} />
                </Tooltip>
              </CardHeader>
            )}
            {/* refresh scheduler settings summary */}
            <SchedulerPanel className="scheduler-panel">
              {!disableHeader && (
                <>
                  {currentSchedule.repeat_type === "hourly" && <span>{getHourlyText()}</span>}
                  {currentSchedule.repeat_type === "daily" && <span>Daily</span>}
                  {currentSchedule.repeat_type === "weekly" &&
                    currentSchedule.days_of_week?.length === 1 && (
                      <span>
                        {
                          WEEKDAYS.find(day => day.dataKey === currentSchedule.days_of_week?.[0])
                            ?.label
                        }
                      </span>
                    )}
                  {currentSchedule.repeat_type === "weekly" &&
                    currentSchedule.days_of_week?.length !== 1 && (
                      <span>
                        {WEEKDAYS.filter(day => currentSchedule.days_of_week?.includes(day.dataKey))
                          .map(day => day.shortLabel)
                          .join(", ")}
                      </span>
                    )}
                  {currentSchedule.repeat_type !== "hourly" && (
                    <>
                      <span> at </span>
                      <span>
                        {scheduledHourToTime(getTZAdjustedHour(currentSchedule.hour || 0))}
                      </span>
                    </>
                  )}
                </>
              )}

              {/* snapshot scheduler settings summary */}
              {!editMode &&
                currentSnapshotSchedule &&
                currentSnapshotSchedule.repeat_type === "weekly" &&
                currentSnapshotSchedule.days_of_week?.length === 1 && (
                  <div>
                    Snapshots: Weekly {currentSnapshotSchedule.days_of_week?.length > 0 ? "(" : ""}
                    {WEEKDAYS.filter(day =>
                      currentSnapshotSchedule.days_of_week?.includes(day.dataKey)
                    )
                      .map(day => day.shortLabel)
                      .join(", ")}
                    {currentSnapshotSchedule.days_of_week?.length > 0 ? ")" : ""}
                  </div>
                )}
              {!editMode &&
                currentSnapshotSchedule &&
                currentSnapshotSchedule.repeat_type === "monthly" &&
                currentSnapshotSchedule.days_of_month!.length === 1 && (
                  <div>
                    Snapshots: Monthly (Day{" "}
                    {currentSnapshotSchedule.days_of_month!.length > 0 &&
                      currentSnapshotSchedule.days_of_month![0]}
                    )
                  </div>
                )}
              {!editMode && currentSnapshotSchedule && !currentSnapshotSchedule.repeat_type && (
                <div>Snapshots: None</div>
              )}
            </SchedulerPanel>
          </StyledPanel>
        )}
      </>
    </Card>
  );
};
