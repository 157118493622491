import { InfoCircleFilled } from "@ant-design/icons";
import { FeatureFlag } from "@coeff/api";
import { message } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGate } from "statsig-react";
import styled from "styled-components";

import { AppThunkDispatch, getClientFlags } from "../actions";
import { getUserSettings, updateUserSettings } from "../actions/user_settings";
import { useAdminExtSettings, useUpdateAdminExtSettings } from "../api";
import {
  Button,
  Checkbox,
  ConfirmBox,
  LoaderWithPerfTimings,
  Tooltip,
  Typography,
} from "../components";
import { COLORS } from "../constants";
import { AppState } from "../store";
import { sentryCapture, useTrack } from "../utils";

import { Header } from "./Dashboard/components";

const Container = styled.div`
  padding: 20px 30px;
`;

const CheckBoxContainer = styled.div`
  margin: 8px 0 4px 0;
  font-weight: bold;
`;

const HelpText = styled.div`
  color: ${COLORS.black45};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  margin-left: 24px;
`;

const RedirectHelpText = styled.div`
  color: ${COLORS.black45};
  font-size: 14px;
  line-height: 14px;
  margin-top: 24px;
`;

const StyledInfoCircleFilled = styled(InfoCircleFilled)`
  color: ${COLORS.black25};
  margin: 3px 0px 0px 5px;
  font-style: italic;
`;

const TooltipTitleWrap = styled.div`
  color: ${COLORS.black};
  background: ${COLORS.white};
  margin: 0px;
  padding: 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StyledLink = styled.a`
  margin-left: 2px;
  color: ${COLORS.coeblue5};
`;

const StyledButton = styled(Button)`
  font-weight: 600;
  font-size: 16px;
  color: ${COLORS.coeblue5};
`;

const OkButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const Capitalized = styled.span`
  text-transform: capitalize;
`;

export const Settings: React.FC = () => {
  const [showSSODisableModal, setShowSSODisableModal] = useState<boolean>(false);

  const { value: ShowAdminDisableConnectionSharing } = useGate(
    FeatureFlag.ShowAdminDisableConnectionSharing
  );

  const { value: enableRevampedAdminSettings } = useGate(FeatureFlag.EnableRevampedAdminSettings);

  const track = useTrack();

  const dispatch = useDispatch<AppThunkDispatch>();

  const { mutateAsync: updateAdminExtSettings, isLoading: isLoadingUpdateAdminExtSettings } =
    useUpdateAdminExtSettings();

  const clientFlags = useSelector((state: AppState) => state.app.clientFlags);

  const { data: adminExtSettings, isLoading: isLoadingAdminExtSettings } = useAdminExtSettings({
    enabled: Boolean(clientFlags?.is_gsuite_domain_admin),
  });

  const { user_settings, loading } = useSelector((state: AppState) => ({
    user_settings: state.userSettings.user_settings,
    loading: state.userSettings.loading,
  }));

  const [_, contextHolder] = message.useMessage();

  useEffect(() => {
    document.title = "Settings - Coefficient Workspace";
    track("workspace_settings_viewed");
    dispatch(getUserSettings());
  }, []);

  const handleSubscribedToWeeklyEmailChange = async (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      track("workspace_settings_weekly_email_subscribed");
    } else {
      track("workspace_settings_weekly_email_unsubscribed");
    }

    try {
      message.loading("Saving");

      await dispatch(
        updateUserSettings({
          user_extra_type: "user_settings",
          user_extra: {
            user_settings: {
              subscribed_to_weekly_email: event.target.checked,
            },
          },
        })
      );

      await dispatch(getUserSettings());

      message.success("Settings saved");
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const handleToggleCloudDatasets = async (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      track("workspace_settings_datasets_enabled");
    } else {
      track("workspace_settings_datasets_disabled");
    }

    try {
      message.loading("Saving");

      await updateAdminExtSettings({
        ...adminExtSettings,
        cloud_table_disabled: !adminExtSettings?.cloud_table_disabled,
      });

      await dispatch(getClientFlags());

      message.success("Settings saved");
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const handleToggleGptFeatures = async (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      track("workspace_settings_gpt_features_enabled");
    } else {
      track("workspace_settings_gpt_features_disabled");
    }

    try {
      message.loading("Saving");

      await updateAdminExtSettings({
        ...adminExtSettings,
        gpt_features_disabled: !adminExtSettings?.gpt_features_disabled,
      });

      await dispatch(getClientFlags());

      message.success("Settings saved");
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const handleToggleDataSourceConnectionSharing = async (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      track("workspace_settings_connection_sharing_enabled");
    } else {
      track("workspace_settings_connection_sharing_disabled");
    }

    try {
      message.loading("Saving");

      await updateAdminExtSettings({
        ...adminExtSettings,
        connection_sharing_disabled_by_admin:
          !adminExtSettings?.connection_sharing_disabled_by_admin,
      });

      await dispatch(getClientFlags());

      message.success("Settings saved");
    } catch (error) {
      message.error("Something went wrong. Please try again.");

      sentryCapture({
        error,
        name: "Update admin connection sharing settings : POST /api_admin_ext/settings",
      });
    }
  };

  const handleToggleSSO = async () => {
    setShowSSODisableModal(false);

    track("workspace_settings_sso_disabled");

    try {
      message.loading("Saving");

      await updateAdminExtSettings({
        ...adminExtSettings,
        sso_enabled: false,
      });

      await dispatch(getClientFlags());

      message.success("Saved successfully");
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const OidcSsoFeatureEnabled = clientFlags?.show_sso_config_ui;
  const idProvider = adminExtSettings?.identity_provider ?? "";

  return (
    <Container>
      <Header>
        <h1>Settings</h1>
      </Header>

      {contextHolder}

      {loading ? (
        <LoaderWithPerfTimings name="Settings" />
      ) : (
        <Content>
          <div>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              My Settings
            </Typography>

            <CheckBoxContainer>
              <Checkbox
                checked={!user_settings || user_settings.user_settings?.subscribed_to_weekly_email}
                onChange={handleSubscribedToWeeklyEmailChange}
              >
                Receive weekly activity emails
                <Tooltip
                  title={
                    <TooltipTitleWrap>
                      This does not opt you out of marketing emails. Click “Unsubscribe” in a
                      marketing email to opt out.
                    </TooltipTitleWrap>
                  }
                  color={COLORS.white}
                  placement="top"
                >
                  <StyledInfoCircleFilled />
                </Tooltip>
              </Checkbox>

              <HelpText>Emails include scheduled import statuses and team activity</HelpText>
            </CheckBoxContainer>
          </div>
          {clientFlags?.is_gsuite_domain_admin && (
            <div>
              {!enableRevampedAdminSettings && (
                <>
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Admin Settings
                  </Typography>
                  <CheckBoxContainer>
                    <Checkbox
                      disabled={isLoadingUpdateAdminExtSettings || isLoadingAdminExtSettings}
                      checked={!adminExtSettings?.cloud_table_disabled}
                      onChange={handleToggleCloudDatasets}
                    >
                      Enable cloud datasets
                    </Checkbox>

                    <HelpText>
                      Allows user to save spreadsheet data to Coefficient's secure cloud
                    </HelpText>
                  </CheckBoxContainer>
                  <CheckBoxContainer>
                    <Checkbox
                      disabled={isLoadingUpdateAdminExtSettings || isLoadingAdminExtSettings}
                      checked={!adminExtSettings?.gpt_features_disabled}
                      onChange={handleToggleGptFeatures}
                    >
                      Enable GPT features
                    </Checkbox>

                    <HelpText>
                      Allows users to access AI features powered by OpenAI's ChatGPT
                    </HelpText>
                  </CheckBoxContainer>

                  {ShowAdminDisableConnectionSharing && (
                    <CheckBoxContainer>
                      <Checkbox
                        disabled={isLoadingUpdateAdminExtSettings || isLoadingAdminExtSettings}
                        checked={!adminExtSettings?.connection_sharing_disabled_by_admin}
                        onChange={handleToggleDataSourceConnectionSharing}
                      >
                        Enable connection sharing
                      </Checkbox>

                      <HelpText>
                        Allows users to share connections with other users in your domain(s)
                      </HelpText>
                    </CheckBoxContainer>
                  )}

                  {OidcSsoFeatureEnabled && (
                    <CheckBoxContainer>
                      <Checkbox
                        disabled={
                          isLoadingUpdateAdminExtSettings ||
                          isLoadingAdminExtSettings ||
                          !adminExtSettings?.sso_enabled
                        }
                        checked={adminExtSettings?.sso_enabled}
                        onChange={() => setShowSSODisableModal(true)}
                      >
                        Enable SSO Authentication
                      </Checkbox>
                      <StyledLink href="/dashboard/settings/configure-sso">
                        {adminExtSettings?.sso_enabled ? "Manage" : "Configure"}
                      </StyledLink>
                      <HelpText>
                        Requires users to sign in with
                        {idProvider ? (
                          <Capitalized> {idProvider} </Capitalized>
                        ) : (
                          " your SSO provider "
                        )}
                        before using Coefficient
                      </HelpText>
                    </CheckBoxContainer>
                  )}
                </>
              )}
              {enableRevampedAdminSettings && (
                <StyledLink href="/dashboard/admin/settings">Manage admin settings</StyledLink>
              )}
            </div>
          )}
        </Content>
      )}
      <ConfirmBox
        title="Please Confirm"
        visible={showSSODisableModal}
        okButton={
          <OkButtonWrapper>
            <StyledButton type="text">Yes, proceed</StyledButton>
          </OkButtonWrapper>
        }
        cancelButton={<StyledButton type="text">Cancel</StyledButton>}
        onOk={handleToggleSSO}
        onCancel={() => setShowSSODisableModal(false)}
        width={420}
        closable={true}
      >
        <p>
          Are you sure you want to disable <Capitalized>{idProvider}</Capitalized> SSO?
        </p>
        <p>
          Users from your domain will no longer be required to sign in with{" "}
          <Capitalized>{idProvider}</Capitalized> before using Coefficient.
        </p>
      </ConfirmBox>
    </Container>
  );
};
