import { DataImportSchedule, DataImportScheduleRepeatType } from "@coeff/api";
import { Radio, Switch as SwitchAD } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

import {
  PaperContentIndent,
  Button,
  TextWithIcon,
  Typography,
  DateIcon,
  BorderedSelect,
} from "../../components";
import { COLORS, HOURLY, WEEKDAYS } from "../../constants";
import { getDefaultSchedule, getTZAdjustedHour, getTimeZone, hourToTime } from "../../utils";

import { ScheduleText } from "./ScheduleText";

const { Option } = BorderedSelect;

const StyledPaperContentIndent = styled(PaperContentIndent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const Switch = styled(SwitchAD)`
  &.ant-switch-checked {
    background-color: ${COLORS.teal};
  }
`;

const RefreshPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-radio-button-wrapper {
    border: none !important;
    color: ${COLORS.black};
    padding: 0px 10px;

    &.ant-radio-button-wrapper-checked {
      background-color: ${COLORS.teal};
      color: ${COLORS.white};
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: transparent;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  .ant-select {
    .ant-select-selector {
      border-radius: 4px;
      border-color: transparent !important;
    }
  }
`;

const CheckboxGroup = styled.div`
  display: flex;

  && .ant-btn-default {
    background-color: ${COLORS.white};
  }

  .ant-btn {
    border-radius: 0px;
    border: none;
    box-shadow: none;
    width: 28px;
    padding: 0px;
    font-weight: bold;
    color: ${COLORS.teal};

    &.ant-btn-primary {
      background-color: ${COLORS.teal};
      color: ${COLORS.white};
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`;

const StyledSelect = styled(BorderedSelect)`
  &.ant-select {
    .ant-select-selector {
      border: 2px solid ${COLORS.black15};
    }
  }
`;

const ToggleEditButton = styled(Button)`
  align-self: flex-end;
`;

type Props = {
  disabled?: boolean;
  value: DataImportSchedule | undefined;
  onChange: (value?: DataImportSchedule) => void;
  isIndented?: boolean;
  initialIsEditMode?: boolean;
  hideCloseButton?: boolean;
};

const uniqueId = parseInt(String(Math.random() * 100), 10);

export const ScheduleFormFields: React.FC<Props> = ({
  disabled,
  value,
  onChange,
  isIndented,
  initialIsEditMode,
  hideCloseButton,
}) => {
  // Keep an internal copy of the schedule so that when the user toggles
  // scheduling off, we're able to provide `undefined` as a value to the
  // parent while still displaying and keep tracking of the date the
  // user was had before.
  const [schedule, setSchedule] = useState(
    value ? { ...value } : getDefaultSchedule({ uniqueId, baseHour: 11 })
  );

  const [isEditMode, setIsEditMode] = useState<boolean>(initialIsEditMode ?? false);

  const [isEnabled, setIsEnabled] = useState(Boolean(value));

  // All changes to schedule must go through this function to ensure that
  // the internal state and the parent are synchronised correctly.
  const handleChange = (newSchedule: DataImportSchedule) => {
    setSchedule(newSchedule);

    if (isEnabled) {
      onChange(newSchedule);
    }
  };

  const handleSchedulerToggle = (checked: boolean) => {
    setIsEnabled(checked);

    onChange(checked ? schedule : undefined);

    if (!checked) {
      setIsEditMode(false);
    }
  };

  const handleHourlyIntervalChange = (every_n_hours: number) => {
    handleChange({ ...schedule, every_n_hours, tz_name: getTimeZone() });
  };

  const handleTimeChange = (hour: number) => {
    handleChange({ ...schedule, hour, tz_name: getTimeZone() });
  };

  const handleDayChange = (day: number) => () => {
    const value = { ...schedule };

    let days = [...(value.days_of_week ?? [])];

    if (value.days_of_week?.includes(day)) {
      days = days.filter((d: number) => d !== day);
    } else {
      days.push(day);
    }

    handleChange({ ...value, days_of_week: days });
  };

  const handleRepeatTypeChange = (repeat_type: DataImportScheduleRepeatType) => {
    handleChange({
      ...schedule,
      repeat_type,
    });
  };

  return (
    <>
      <TextWithIcon
        title={<Typography fontWeight="bold">Refresh Schedule</Typography>}
        icon={<DateIcon fill={COLORS.black85} />}
        action={
          <Switch
            disabled={disabled}
            size="small"
            onChange={handleSchedulerToggle}
            checked={isEnabled}
          />
        }
      />

      <StyledPaperContentIndent size={isIndented ? "default" : "none"} leftOnly={true}>
        {isEditMode ? (
          <>
            <div>
              <Typography color="textSecondary" variant="body3">
                Set a refresh schedule so your dataset is <br />
                always up to date
              </Typography>
            </div>

            <GridContainer>
              <RefreshPanel>
                <section>
                  <Radio.Group
                    disabled={disabled}
                    value={schedule.repeat_type}
                    onChange={e => handleRepeatTypeChange(e.target.value)}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="hourly">Hourly</Radio.Button>

                    <Radio.Button value="daily">Daily</Radio.Button>

                    <Radio.Button value="weekly">Weekly</Radio.Button>
                  </Radio.Group>
                </section>

                {schedule.repeat_type === "weekly" && (
                  <section>
                    <CheckboxGroup>
                      {WEEKDAYS.map(day => (
                        <Button
                          key={day.dataKey}
                          disabled={disabled}
                          type={
                            schedule.days_of_week?.includes(day.dataKey) ? "primary" : "default"
                          }
                          onClick={handleDayChange(day.dataKey)}
                        >
                          {day.label.charAt(0)}
                        </Button>
                      ))}
                    </CheckboxGroup>
                  </section>
                )}

                {schedule.repeat_type !== "hourly" && (
                  <section>
                    <StyledSelect
                      disabled={disabled}
                      dropdownMatchSelectWidth={false}
                      value={getTZAdjustedHour(schedule)}
                      onSelect={hour => handleTimeChange(hour as number)}
                    >
                      {Array.from(Array(24), (_, i) => i).map((hr: number) => {
                        return (
                          <Option key={hr} value={hr}>
                            {hourToTime(hr)}
                          </Option>
                        );
                      })}
                    </StyledSelect>
                  </section>
                )}

                {schedule.repeat_type === "hourly" && (
                  <section>
                    <StyledSelect
                      dropdownMatchSelectWidth={false}
                      value={schedule.every_n_hours || 1}
                      onSelect={interval => handleHourlyIntervalChange(interval as number)}
                    >
                      {HOURLY.map(({ label, value }, idx) => (
                        <Option key={idx} value={value}>
                          {label}
                        </Option>
                      ))}
                    </StyledSelect>
                  </section>
                )}
              </RefreshPanel>

              {!hideCloseButton ? (
                <ToggleEditButton
                  noPadding
                  size="small"
                  type="link"
                  onClick={() => setIsEditMode(false)}
                >
                  close
                </ToggleEditButton>
              ) : null}
            </GridContainer>
          </>
        ) : (
          <GridContainer>
            <ScheduleText schedule={schedule} />

            <ToggleEditButton
              disabled={disabled}
              noPadding
              size="small"
              type="link"
              onClick={() => setIsEditMode(true)}
            >
              edit
            </ToggleEditButton>
          </GridContainer>
        )}
      </StyledPaperContentIndent>
    </>
  );
};
