import { DefaultApi, SnowflakeAppInfo } from "@coeff/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Ajv from "ajv";
import { Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import * as sqlHighlight from "sql-highlight";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  Alert,
  Button,
  ErrorMessage,
  Form,
  ImportTypeIcon,
  List,
  LoaderWithPerfTimings,
  NewWindow,
  SettingsLayout,
  TextArea,
  Tooltip,
  Typography,
} from "../components";
import { config } from "../config";
import { COLORS } from "../constants";
import { AppState } from "../store";
import { useApiContext, useTrack } from "../utils";

import { SnowflakeOAuthManageSettings } from "./SnowflakeOAuthManageSettings";

const StyledAlert = styled(Alert)`
  line-height: 1.8em;

  code {
    white-space: nowrap;
    background-color: ${COLORS.black4};
    padding: 4px 6px;
  }
`;

const StyledLabel = styled.div`
  label {
    font-weight: bold;
  }

  margin: 6px 0;
`;

const SQLCodeBlockContainer = styled.div`
  position: relative;
  background-color: ${COLORS.black4};
  white-space: pre;
  font-family: monospace;
  font-size: 12px;
  padding: 16px 24px;
  max-width: 800px;

  & > button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-weight: normal;
  }

  .sql-hl-bracket {
  }

  .sql-hl-function {
    color: #087959;
  }

  .sql-hl-keyword {
    font-weight: 500;
    color: #085bd7;
  }

  .sql-hl-identifier {
    color: #191e24;
  }

  .sql-hl-number {
    color: #087959;
  }

  .sql-hl-special {
  }

  .sql-hl-string {
    color: #860112;
  }

  .sql-hl-whitespace {
  }
`;

const StyledInstructionsList = styled.ol`
  > li {
    margin-bottom: 16px;
  }
`;

const StyledTextArea = styled(TextArea)`
  font-family: ui-monospace, "Courier New", monospace;
  font-weight: 600;
  width: 800px;
  resize: none;
`;

type SQLCodeBlockProps = {
  sql: string;
};

const StyledLink = styled(Link)`
  margin: 8px 16px;
`;

const { Item } = List;

const DataSourceSelections = styled.div`
  border-radius: 6px;
  cursor: default !important;

  .ant-list-item {
    .actionswrap {
      visibility: visible !important;
      border-radius: 6px !important;
    }
  }
`;

const StyledItem = styled(Item)`
  && {
    background: ${COLORS.black2};
    border: none;
    cursor: pointer;
    position: relative;
    margin-bottom: 8px;

    &:hover,
    &:focus-within,
    &:focus,
    &:active {
      background-color: ${COLORS.black4};

      .actionswrap {
        visibility: visible;
      }
    }

    .actionswrap {
      visibility: hidden;
    }

    .ant-list-item {
      margin: 12px 15px !important;
      border: 10px;
      background-color: ${COLORS.black4} !important;
      border-radius: 6px !important;
      cursor: default !important;

      .actionswrap {
        visibility: visible !important;
        border-radius: 6px !important;
      }
    }

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-title {
      color: ${COLORS.black45};
      line-height: 1.25;

      h4 {
        margin: 0px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
      }
    }

    .ant-list-item-meta-description {
      color: ${COLORS.black85};
      font-size: 14px;
      font-weight: bold;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
`;

const SQLCodeBlock: React.FC<SQLCodeBlockProps> = props => {
  const [justClickedCounter, setJustClickedCounter] = useState(0);

  const onCopyClicked = async () => {
    await navigator.clipboard.writeText(props.sql);
    setJustClickedCounter(counter => counter + 1);
    setTimeout(() => {
      setJustClickedCounter(counter => counter - 1);
    }, 4000);
  };

  const justClicked = justClickedCounter > 0;

  const sqlSegments = sqlHighlight.getSegments(props.sql).map(segment =>
    // Snowflake-specific keywords
    ["SECURITY", "INTEGRATION", "INTEGRATIONS", "RETURN"].includes(segment.content)
      ? { name: "keyword", content: segment.content }
      : segment
  );

  const highlightedSQL = sqlSegments.map((segment, i) => (
    <span key={i} className={`sql-hl-${segment.name}`}>
      {segment.content}
    </span>
  ));

  return (
    <SQLCodeBlockContainer>
      {highlightedSQL}
      <Button
        size="small"
        type={justClicked ? "default" : "primary"}
        htmlType="button"
        onClick={() => onCopyClicked()}
      >
        {justClicked ? "Copied" : "Copy"}
      </Button>
    </SQLCodeBlockContainer>
  );
};

const PopoutLink: React.FC<React.PropsWithChildren<{ href: string }>> = ({ children, href }) => {
  return (
    <a target="_blank" href={href}>
      {children} <NewWindow fill={COLORS.black65} style={{ marginRight: 3 }} />
    </a>
  );
};

const fetchSnowflakeAppInfo = async (api: DefaultApi): Promise<SnowflakeAppInfo[] | null> => {
  const response = await api.listSnowflakeAppInfo();
  if (response.data.apps_list.length) {
    return response.data.apps_list;
  }
  return null;
};

const upsertSnowflakeAppInfo = async (api: DefaultApi, validConfigJSON: ValidConfigJSON) => {
  await api.upsertSnowflakeAppInfo({
    upsert_snowflake_app_info_request: {
      snowflake_account_id: validConfigJSON["a"],
      client_id: validConfigJSON["o"]["OAUTH_CLIENT_ID"],
      client_secret: validConfigJSON["o"]["OAUTH_CLIENT_SECRET"],
    },
  });
};

const ajv = new Ajv();
const configJSONValidator = ajv.compile({
  type: "object",
  properties: {
    a: { type: "string" },
    o: {
      type: "object",
      properties: {
        OAUTH_CLIENT_SECRET: { type: "string" },
        OAUTH_CLIENT_ID: { type: "string" },
      },
      required: ["OAUTH_CLIENT_SECRET", "OAUTH_CLIENT_ID"],
      additionalProperties: true,
    },
  },
  required: ["a", "o"],
  additionalProperties: false,
});

type ValidConfigJSON = {
  a: string;
  o: {
    OAUTH_CLIENT_SECRET: string;
    OAUTH_CLIENT_ID: string;
  };
};

const tryParseUserEnteredConfigJSON = (text: string): ValidConfigJSON | null => {
  let parsedJSON: any;
  try {
    parsedJSON = JSON.parse(text);
  } catch (err) {
    // Invalid JSON
    return null;
  }
  const validateResult = configJSONValidator(parsedJSON);
  // Since we are not using $async: true JSON schemas, the result will always be a boolean
  // (and not a promise) at runtime
  if (!validateResult) {
    // JSON does not conform to schema
    return null;
  }
  return parsedJSON;
};

const getCreateOrAlterIntegrationSQL = (): string => {
  // Note: we use a SQL snippet with two statements (`CREATE ... IF NOT EXISTS` followed by
  // `ALTER`), since the simpler, single-statement alternatives have the following drawbacks:
  //   - `CREATE OR REPLACE ...` regenerates OAuth the client ID and secret, invalidating
  //     previously granted Snowflake OAuth tokens
  //   - `CREATE ... IF NOT EXISTS` would not update the existing integration with the latest
  //     recommended settings

  const integrationName = config.SNOWFLAKE_SECURITY_INTEGRATION_NAME;
  const oauthRedirectUrl = `${config.OAUTH_REDIRECT_URL_BASE}/snowflake`;

  // prettier-ignore
  const sql =
`BEGIN
    CREATE SECURITY INTEGRATION IF NOT EXISTS ${integrationName}
      TYPE = oauth
      OAUTH_CLIENT = custom
      OAUTH_CLIENT_TYPE = confidential
      OAUTH_REDIRECT_URI = '${oauthRedirectUrl}';
    ALTER SECURITY INTEGRATION ${integrationName} SET
      OAUTH_REDIRECT_URI = '${oauthRedirectUrl}'
      OAUTH_ISSUE_REFRESH_TOKENS = true
      OAUTH_REFRESH_TOKEN_VALIDITY = 7776000
      OAUTH_USE_SECONDARY_ROLES = 'IMPLICIT'
      ENABLED = true;
END;`

  return sql;
};

const getSelectOAuthInfoSQL = (): string => {
  // Returns a Snowflake SQL snippet which retrieves the Snowflake account identifier
  // and OAuth client ID/secret

  const integrationName = config.SNOWFLAKE_SECURITY_INTEGRATION_NAME;

  // prettier-ignore
  const sql =
`SELECT to_json(object_construct(
  'a', lower(concat(current_organization_name(), '-', current_account_name())),
  'o', parse_json(system$show_oauth_client_secrets('${integrationName}'))
)) as "Configuration JSON";`;

  return sql;
};

export const SnowflakeOAuthSettings: React.FC = () => {
  const clientFlags = useSelector((state: AppState) => state.app.clientFlags);

  const track = useTrack();

  const { url } = useRouteMatch();

  const [formError, setFormError] = useState<{
    title: string;
    message: string;
  }>();

  const [userEnteredConfigJSON, setUserEnteredConfigJSON] = useState("");

  const [_, contextHolder] = message.useMessage();

  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);

  const isAddNewAccount = searchParams.get("addNewAccount") === "true";

  const [showAddNewAccountForm, setShowAddNewAccountForm] = useState(isAddNewAccount);

  useEffect(() => {
    document.title = "Snowflake OAuth Settings - Coefficient Workspace";
    track("workspace_snowflake_oauth_settings_viewed");
  }, []);

  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const fetchAppInfoQuery = useQuery(
    [QueryCacheKey.SNOWFLAKE_APP_INFO],
    () => fetchSnowflakeAppInfo(api),
    { cacheTime: 0 }
  );
  const { data: snowflakeAppInfo } = fetchAppInfoQuery;

  const upsertAppInfoMutation = useMutation(
    (validConfigJSON: ValidConfigJSON) => upsertSnowflakeAppInfo(api, validConfigJSON),
    {
      onSuccess: () => {
        message.success("Snowflake OAuth configured successfully");
        setShowAddNewAccountForm(false);
        setFormError(undefined);
      },
      onError: (error: any) => {
        const errorMessage =
          error.response?.data?.message || "Something went wrong. Please try again.";
        setFormError({
          title: "Configuration already exists",
          message: errorMessage,
        });
        message.error("Configuration already exists");
      },
      onSettled: () => queryClient.invalidateQueries([QueryCacheKey.SNOWFLAKE_APP_INFO]),
    }
  );

  if (clientFlags?.is_gmail_like_domain) {
    return (
      <SettingsLayout dataSourceType="snowflake" title="Snowflake OAuth Settings" width="narrow">
        <StyledAlert
          type="error"
          showIcon
          message="Snowflake OAuth is not supported for your domain"
          description={`Snowflake OAuth is not supported for ${
            clientFlags.email.split("@")[1]
          }. Please use a different account to configure Snowflake OAuth.`}
        />
      </SettingsLayout>
    );
  }

  if (
    clientFlags?.admin_emails &&
    clientFlags?.admin_emails.length > 0 &&
    !clientFlags?.admin_emails?.includes(clientFlags.email)
  ) {
    return (
      <SettingsLayout dataSourceType="snowflake" title="Snowflake OAuth Settings" width="narrow">
        <StyledAlert
          type="error"
          showIcon
          message="You need to be an admin to access this page"
          description="Please contact your domain admin for configuring or managing Snowflake accounts."
        />
      </SettingsLayout>
    );
  }

  const isLoading = [fetchAppInfoQuery, upsertAppInfoMutation].some(x => x.isLoading);

  if (isLoading) {
    return (
      <SettingsLayout title="Snowflake OAuth Settings" dataSourceType="snowflake" width="narrow">
        {contextHolder}
        <LoaderWithPerfTimings name="SnowflakeOAuthSettings" />
      </SettingsLayout>
    );
  }

  if (fetchAppInfoQuery.isError) {
    return (
      <SettingsLayout dataSourceType="snowflake" title="Snowflake OAuth Settings" width="narrow">
        {contextHolder}
        <StyledAlert
          type="error"
          showIcon
          message="Error loading Snowflake OAuth Settings"
          description="Please try refreshing this page, or contact support@coefficient.io"
        />
      </SettingsLayout>
    );
  }

  if (snowflakeAppInfo && !showAddNewAccountForm) {
    // Snowflake OAuth already configured
    return (
      <SettingsLayout dataSourceType="snowflake" title="Snowflake OAuth Settings" width="narrow">
        {contextHolder}
        <StyledAlert
          type="info"
          showIcon
          message="Snowflake OAuth has been configured for your domain"
          description="Connect to Snowflake using the Coefficient sidebar in Google Sheets / Excel."
        />
        <Form>
          <StyledLabel>
            <label>Accounts</label>
          </StyledLabel>
        </Form>
        {snowflakeAppInfo.map((appInfo, index) => (
          <DataSourceSelections key={index}>
            <StyledItem onClick={() => null}>
              <Item.Meta
                title="Account ID"
                avatar={<ImportTypeIcon type={"snowflake"} />}
                description={appInfo.account_id}
              />
              <StyledLink to={`${url}/${appInfo.account_id}`}>Manage</StyledLink>
              <Switch>
                <Route path={`${url}:accountId`}>
                  Manage
                  <SnowflakeOAuthManageSettings />
                </Route>
              </Switch>
            </StyledItem>
          </DataSourceSelections>
        ))}

        <Button
          style={{ width: 200 }}
          onClick={() => {
            setShowAddNewAccountForm(true);
            if (formError) {
              setFormError(undefined);
            }
          }}
        >
          Add Connection
        </Button>
      </SettingsLayout>
    );
  }

  const placeholderConfigJSON = JSON.stringify(
    {
      a: "<snowflake_account_id>",
      o: {
        OAUTH_CLIENT_ID: "<oauth_client_id>",
        OAUTH_CLIENT_SECRET: "<oauth_client_secret>",
        OAUTH_CLIENT_SECRET_2: "<oauth_client_secret_2>",
      },
    },
    null,
    2
  );

  const handleConfigJSONEntered = (text: string) => {
    try {
      const parsedJSON = JSON.parse(text);
      text = JSON.stringify(parsedJSON, null, 2);
    } catch (err) {}
    setUserEnteredConfigJSON(text);
  };

  const validConfigJSON = tryParseUserEnteredConfigJSON(userEnteredConfigJSON);

  return (
    <SettingsLayout dataSourceType="snowflake" title="Snowflake OAuth Settings">
      {formError && <ErrorMessage {...formError} />}
      {contextHolder}
      <p>
        Setting up{" "}
        <PopoutLink href="https://docs.snowflake.com/en/user-guide/oauth-custom">
          Snowflake OAuth
        </PopoutLink>{" "}
        allows your organization's Coefficient users to connect to Snowflake securely using
        Snowflake's built-in OAuth service.
      </p>
      <StyledAlert
        type="info"
        showIcon
        message={
          <span>
            To configure Snowflake OAuth, you must be a Snowflake <code>ACCOUNTADMIN</code> or have
            a custom role with <code>CREATE INTEGRATION</code> privileges.
          </span>
        }
      />
      <div>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Instructions
        </Typography>
        <StyledInstructionsList>
          <li>
            <p>
              Log in to <PopoutLink href="https://app.snowflake.com/">Snowflake</PopoutLink> and
              create a new SQL Worksheet.
            </p>
          </li>
          <li>
            <p>
              Select the <code>ACCOUNTADMIN</code> role or, alternatively, a custom role with the{" "}
              <PopoutLink href="https://docs.snowflake.com/en/sql-reference/sql/create-security-integration-oauth-snowflake#access-control-requirements">
                appropriate privileges
              </PopoutLink>
              .
            </p>
          </li>
          <li>
            <p>Run this SQL to register Coefficient as a Snowflake OAuth app:</p>
            <SQLCodeBlock sql={getCreateOrAlterIntegrationSQL()} />
          </li>
          <li>
            <p>
              Run the following SQL command to retrieve your Snowflake OAuth configuration
              information:
            </p>
            <SQLCodeBlock sql={getSelectOAuthInfoSQL()} />
          </li>
          <li>
            <p>Copy the SQL command output from the previous step and paste it below:</p>
            <Tooltip
              placement="right"
              open={!!userEnteredConfigJSON && !validConfigJSON}
              title="Invalid configuration JSON"
            >
              <StyledTextArea
                status={!!userEnteredConfigJSON && !validConfigJSON ? "error" : undefined}
                maxLength={1000}
                rows={8}
                spellCheck={false}
                placeholder={placeholderConfigJSON}
                value={userEnteredConfigJSON}
                onChange={e => handleConfigJSONEntered(e.target.value)}
                onPaste={e => {
                  e.preventDefault();
                  handleConfigJSONEntered(e.clipboardData.getData("text"));
                }}
              />
            </Tooltip>
          </li>
          <Space />
          <Button
            type="primary"
            style={{ width: 200 }}
            disabled={!validConfigJSON}
            onClick={() => validConfigJSON && upsertAppInfoMutation.mutate(validConfigJSON)}
          >
            Save
          </Button>
        </StyledInstructionsList>
      </div>
    </SettingsLayout>
  );
};
