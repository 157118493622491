import { DataSourceType } from "@coeff/api";
import { AllowOrDisallowOption, DomainSettings } from "@coeff/api-admin-ext";
import { message, ModalProps, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { AppThunkDispatch, getClientFlags } from "../../../actions";
import { useAdminDomainUsers, useAdminExtSettings, useUpdateAdminExtSettings } from "../../../api";
import {
  Alert,
  AutoCompleteTagsInput,
  Button,
  LoaderWithPerfTimings,
  Modal,
  Option,
  Select as CoeffSelect,
  Switch,
  Typography,
} from "../../../components";
import { COLORS } from "../../../constants";
import { AppState } from "../../../store";
import { isValidEmail, orderByString, sentryCapture, stringSearch, useTrack } from "../../../utils";

const ModalActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: right;
  align-items: center;
  margin-top: 32px;

  .ant-btn {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const ModalButton = styled(Button)<{ $color: string }>`
  &.ant-btn-link {
    padding: 4px 0;
    color: ${props => props.$color};
  }

  &.ant-btn-primary {
    background-color: ${COLORS.coeblue4};
    border-color: ${COLORS.coeblue4};
    color: ${COLORS.white};

    &:active,
    &:hover,
    &:focus {
      background-color: ${COLORS.coeblue6};
      border-color: ${COLORS.coeblue6};
      color: ${COLORS.white};
      box-shadow: none;
    }
  }
`;

const StyledContent = styled.div`
  label {
    font-weight: bold;
  }

  .title {
    padding-right: 40px !important;
  }

  .description {
    color: ${COLORS.black45};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: ${COLORS.coeblue};
  margin: 8px 0px;
  padding: 16px;
  border-radius: 5px;

  #tags-input-container {
    margin-top: 6px;
    margin-left: 20px !important;
    width: 500px;
  }
`;

const StyledRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  .selectionTitle {
    color: ${COLORS.coeblue4};
  }

  .underline {
    padding-right: 16px;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    cursor: pointer;
  }

  .ant-radio-wrapper {
    font-weight: normal;
  }
`;

const FlexBox = styled.div`
  display: flex;
  gap: 8px;
  margin: 6px 0;

  .styledSvg {
    margin-top: 2px;
    margin-left: 4px;
  }

  .styledToogle {
    margin-top: 4px;
    margin-right: 4px;
    margin-left: auto;
  }
`;

const Select = styled(CoeffSelect)`
  && {
    width: 40%;
    margin: 0 20px;

    .ant-select-selector {
      align-items: center;
      width: 100%;
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }

    .ant-select-arrow {
      opacity: 1;
    }

    &:not(.ant-select-focused) {
      .ant-select-selector {
        border-color: ${COLORS.black15};
      }
    }
  }

  &.ds-settings {
    width: 50%;
  }
`;

const ErrorDiv = styled.div`
  color: red;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const StyledSpan = styled.span`
  font-weight: bold;
`;

const StyledTypography = styled(Typography)`
  font-weight: 700;
  line-height: 24px;
  font-size: 16px;
`;

const StyledAlert = styled(Alert)`
  line-height: 1.8em;
  padding: 8px;
`;

export type SettingsType =
  | "writeback_settings"
  | "slack_or_email_settings"
  | "gpt_settings"
  | "cloud_table_settings"
  | "connection_sharing_settings"
  | "allowed_data_sources_settings"
  | undefined;

type Props = ModalProps & {
  isLoading?: boolean;
  onSuccess: () => void;
  settingsType: SettingsType;
};

type FormStateJSON = {
  isDisabled?: boolean;

  domainsList?: string[];
  usersEmailsList?: string[];
  dataSourcesList?: DataSourceType[];

  allowSelectedDomains?: AllowOrDisallowOption;
  allowSelectedUsersEmails?: AllowOrDisallowOption;
  allowSelectedDataSources?: AllowOrDisallowOption;

  filteredDomainsList?: { value: string }[];
  filteredDomainUserEmailsList?: { value: string }[];
  filteredDataSourceTypes?: { value: DataSourceType }[];

  isAllowAllOption?: boolean;
  isAllowSpecificDomains?: boolean;
  isAllowSpecificUserEmails?: boolean;
  isAllowSpecificDataSources?: boolean;
};

const getSettingValueBasedOnType = (
  settingsType: SettingsType,
  adminExtSettings: DomainSettings | undefined
): FormStateJSON | undefined => {
  switch (settingsType) {
    case "writeback_settings":
      return {
        isDisabled: Boolean(adminExtSettings?.domain_level_writeback_settings?.is_disabled),
        domainsList: adminExtSettings?.domain_level_writeback_settings?.domains_list,
        usersEmailsList: adminExtSettings?.domain_level_writeback_settings?.user_emails_list,
        allowSelectedDomains:
          adminExtSettings?.domain_level_writeback_settings?.allow_or_disallow_domains,
        allowSelectedUsersEmails:
          adminExtSettings?.domain_level_writeback_settings?.allow_or_disallow_users,
      };
    case "slack_or_email_settings":
      return {
        isDisabled: adminExtSettings?.slack_or_email_alerts_settings?.is_disabled,
        domainsList: adminExtSettings?.slack_or_email_alerts_settings?.domains_list,
        allowSelectedUsersEmails:
          adminExtSettings?.slack_or_email_alerts_settings?.allow_or_disallow_domains,
      };
    case "gpt_settings":
      return {
        isDisabled: Boolean(adminExtSettings?.gpt_features_settings?.is_disabled),
        domainsList: adminExtSettings?.gpt_features_settings?.domains_list,
        allowSelectedDomains: adminExtSettings?.gpt_features_settings?.allow_or_disallow_domains,
      };
    case "cloud_table_settings":
      return {
        isDisabled: adminExtSettings?.cloud_table_settings?.is_disabled,
        domainsList: adminExtSettings?.cloud_table_settings?.domains_list,
        allowSelectedDomains: adminExtSettings?.cloud_table_settings?.allow_or_disallow_domains,
      };
    case "connection_sharing_settings":
      return {
        isDisabled: adminExtSettings?.connection_sharing_settings?.is_disabled,
        domainsList: adminExtSettings?.connection_sharing_settings?.domains_list,
        allowSelectedDomains:
          adminExtSettings?.connection_sharing_settings?.allow_or_disallow_domains,
      };
    case "allowed_data_sources_settings":
      return {
        dataSourcesList: adminExtSettings?.allowed_data_sources_settings?.data_sources_list,
        allowSelectedDataSources:
          adminExtSettings?.allowed_data_sources_settings?.allow_or_disallow_data_sources,
      };
    default:
      return undefined;
  }
};

const getTitleBasedOnSettingsType = (settingsType: SettingsType) => {
  switch (settingsType) {
    case "writeback_settings":
      return "Exports";
    case "slack_or_email_settings":
      return "Slack or email alerts";
    case "gpt_settings":
      return "GPT features";
    case "cloud_table_settings":
      return "Cloud datasets";
    case "connection_sharing_settings":
      return "Connection sharing";
    case "allowed_data_sources_settings":
      return "Allowed data sources";
    default:
      return "";
  }
};

const getSettingDescriptionBasedOnType = (settingsType: SettingsType) => {
  switch (settingsType) {
    case "writeback_settings":
      return "Allow users to export data from Sheets to other systems";
    case "slack_or_email_settings":
      return "Allow users to send alerts to Slack or Email";
    case "gpt_settings":
      return "Allow users to access AI features powered by OpenAI's ChatGPT";
    case "cloud_table_settings":
      return "Allow users to save spreadsheet data Coefficient's secure cloud";
    case "connection_sharing_settings":
      return "Allow users to share connections with other users in your domain(s)";
    case "allowed_data_sources_settings":
      return "Allow users to only connect to specified data sources";
    default:
      return "";
  }
};

export const AdminSettingsConfigureModal = (props: Props) => {
  const { isLoading, settingsType } = props;

  const track = useTrack();

  const dispatch = useDispatch<AppThunkDispatch>();

  const { mutateAsync: updateAdminExtSettings, isLoading: isLoadingUpdateAdminExtSettings } =
    useUpdateAdminExtSettings();

  const { clientFlags, domains_in_domain_family } = useSelector((state: AppState) => ({
    clientFlags: state.app.clientFlags,
    domains_in_domain_family: state.app.domains_in_domain_family,
  }));

  const allowedDataSources = clientFlags?.all_connectors_metadata
    .map(metadata => ({
      key: metadata.data_source_type,
      name: metadata.display_name,
      isSupported: metadata.is_supported,
    }))
    .filter(connector => connector.isSupported);

  const sortedDataSources = orderByString(allowedDataSources ?? [], "name");

  const [searchValue, setSearchValue] = useState<string>("");

  const [hasError, setHasError] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const showAllowOrDisallowDomainsDropdown = false;

  const showAllowOrDisallowUsersDropdown = false;

  const [formState, setFormState] = useState<FormStateJSON>({
    isDisabled: false,
    domainsList: [],
    usersEmailsList: [],
    dataSourcesList: [],

    allowSelectedDomains: AllowOrDisallowOption.Allow,
    allowSelectedUsersEmails: AllowOrDisallowOption.Allow,
    allowSelectedDataSources: AllowOrDisallowOption.Allow,

    isAllowAllOption: true,
    isAllowSpecificDomains: false,
    isAllowSpecificUserEmails: false,
    isAllowSpecificDataSources: false,
  });

  const {
    data: adminExtSettings,
    isLoading: isLoadingAdminExtSettings,
    isSuccess,
  } = useAdminExtSettings({
    enabled: Boolean(clientFlags?.is_gsuite_domain_admin),
  });

  const { data: domainUsersList, isLoading: isLoadingDomainUsers } = useAdminDomainUsers({
    domain: domains_in_domain_family.toString(),
    offset: 0,
  });

  useEffect(() => {
    const currentSettings = getSettingValueBasedOnType(settingsType, adminExtSettings);
    setFormState({
      isDisabled:
        settingsType === "allowed_data_sources_settings"
          ? false
          : Boolean(currentSettings?.isDisabled),
      domainsList: currentSettings?.domainsList,
      usersEmailsList: currentSettings?.usersEmailsList,
      dataSourcesList: currentSettings?.dataSourcesList,

      // Determines radio option
      isAllowAllOption: !Boolean(
        currentSettings?.domainsList ||
          currentSettings?.usersEmailsList ||
          currentSettings?.dataSourcesList
      ),
      isAllowSpecificDomains: Boolean(currentSettings?.domainsList),
      isAllowSpecificUserEmails: Boolean(currentSettings?.usersEmailsList),
      isAllowSpecificDataSources: Boolean(currentSettings?.dataSourcesList),

      // Determines drop-down allow/disallow value
      allowSelectedDomains: currentSettings?.allowSelectedDomains ?? AllowOrDisallowOption.Allow,
      allowSelectedUsersEmails:
        currentSettings?.allowSelectedUsersEmails ?? AllowOrDisallowOption.Allow,
      allowSelectedDataSources:
        currentSettings?.allowSelectedDataSources ?? AllowOrDisallowOption.Allow,
    });
  }, [isSuccess, settingsType, adminExtSettings]);

  const trackAnalytics = (settingsType: SettingsType) => {
    switch (settingsType) {
      case "writeback_settings":
        if (Boolean(formState.isDisabled)) {
          track("workspace_settings_writebacks_disabled");
        } else {
          track("workspace_settings_writebacks_enabled", {
            allow_all_option_selected: formState.isAllowAllOption,
            selected_domains_list: formState.isAllowSpecificDomains
              ? formState.domainsList
              : undefined,
            allow_or_disallow_domains: formState.isAllowSpecificDomains
              ? formState.allowSelectedDomains
              : undefined,
            selected_user_emails_list: formState.isAllowSpecificUserEmails
              ? formState.usersEmailsList
              : undefined,
            allow_or_disallow_users: formState.isAllowSpecificUserEmails
              ? formState.allowSelectedUsersEmails
              : undefined,
          });
        }
        break;
      case "slack_or_email_settings":
        if (Boolean(formState.isDisabled)) {
          track("workspace_settings_slack_or_email_alerts_disabled");
        } else {
          track("workspace_settings_slack_or_email_alerts_enabled", {
            allow_all_option_selected: formState.isAllowAllOption,
            selected_domains_list: formState.isAllowSpecificDomains
              ? formState.domainsList
              : undefined,
            allow_or_disallow_domains: formState.isAllowSpecificDomains
              ? formState.allowSelectedDomains
              : undefined,
          });
        }
        break;
      case "gpt_settings":
        if (Boolean(formState.isDisabled)) {
          track("workspace_settings_gpt_features_disabled");
        } else {
          track("workspace_settings_gpt_features_enabled", {
            allow_all_option_selected: formState.isAllowAllOption,
            selected_domains_list: formState.isAllowSpecificDomains
              ? formState.domainsList
              : undefined,
            allow_or_disallow_domains: formState.isAllowSpecificDomains
              ? formState.allowSelectedDomains
              : undefined,
          });
        }
        break;
      case "cloud_table_settings":
        if (Boolean(formState.isDisabled)) {
          track("workspace_settings_datasets_disabled");
        } else {
          track("workspace_settings_datasets_enabled", {
            allow_all_option_selected: formState.isAllowAllOption,
            selected_domains_list: formState.isAllowSpecificDomains
              ? formState.domainsList
              : undefined,
            allow_or_disallow_domains: formState.isAllowSpecificDomains
              ? formState.allowSelectedDomains
              : undefined,
          });
        }
        break;
      case "connection_sharing_settings":
        if (Boolean(formState.isDisabled)) {
          track("workspace_settings_connection_sharing_disabled");
        } else {
          track("workspace_settings_connection_sharing_enabled");
        }
        break;
      case "allowed_data_sources_settings":
        track("workspace_allowed_data_source_settings", {
          allow_all_option_selected: formState.isAllowAllOption,
          selected_data_sources_list: formState.isAllowSpecificDataSources
            ? formState.dataSourcesList
            : undefined,
          allow_or_disallow_data_sources: formState.isAllowSpecificDataSources
            ? formState.allowSelectedDataSources
            : undefined,
        });
        break;
      default:
        return;
    }
  };

  const handleSaveSettings = async () => {
    trackAnalytics(settingsType);

    try {
      message.loading("Saving");

      switch (settingsType) {
        case "writeback_settings":
          await updateAdminExtSettings({
            ...adminExtSettings,
            domain_level_writeback_settings: {
              is_disabled: Boolean(formState.isDisabled),
              domains_list:
                !Boolean(formState.isDisabled) && formState.isAllowSpecificDomains
                  ? formState.domainsList
                  : undefined,
              user_emails_list:
                !Boolean(formState.isDisabled) && formState.isAllowSpecificUserEmails
                  ? formState.usersEmailsList
                  : undefined,
              allow_or_disallow_domains:
                !Boolean(formState.isDisabled) && formState.isAllowSpecificDomains
                  ? formState.allowSelectedDomains
                  : undefined,
              allow_or_disallow_users:
                !Boolean(formState.isDisabled) && formState.isAllowSpecificUserEmails
                  ? formState.allowSelectedUsersEmails
                  : undefined,
            },
          });
          break;
        case "slack_or_email_settings":
          await updateAdminExtSettings({
            ...adminExtSettings,
            slack_or_email_alerts_settings: {
              is_disabled: Boolean(formState.isDisabled),
              domains_list:
                !Boolean(formState.isDisabled) && formState.isAllowSpecificDomains
                  ? formState.domainsList
                  : undefined,
              allow_or_disallow_domains:
                !Boolean(formState.isDisabled) && formState.isAllowSpecificDomains
                  ? formState.allowSelectedDomains
                  : undefined,
            },
          });
          break;
        case "gpt_settings":
          await updateAdminExtSettings({
            ...adminExtSettings,
            gpt_features_settings: {
              is_disabled: Boolean(formState.isDisabled),
              domains_list:
                !Boolean(formState.isDisabled) && formState.isAllowSpecificDomains
                  ? formState.domainsList
                  : undefined,
              allow_or_disallow_domains:
                !Boolean(formState.isDisabled) && formState.isAllowSpecificDomains
                  ? formState.allowSelectedDomains
                  : undefined,
            },
          });
          break;
        case "cloud_table_settings":
          await updateAdminExtSettings({
            ...adminExtSettings,
            cloud_table_settings: {
              is_disabled: Boolean(formState.isDisabled),
              domains_list:
                !Boolean(formState.isDisabled) && formState.isAllowSpecificDomains
                  ? formState.domainsList
                  : undefined,
              allow_or_disallow_domains:
                !Boolean(formState.isDisabled) && formState.isAllowSpecificDomains
                  ? formState.allowSelectedDomains
                  : undefined,
            },
          });
          break;
        case "connection_sharing_settings":
          await updateAdminExtSettings({
            ...adminExtSettings,
            connection_sharing_settings: {
              is_disabled: Boolean(formState.isDisabled),
            },
          });
          break;
        case "allowed_data_sources_settings":
          await updateAdminExtSettings({
            ...adminExtSettings,
            allowed_data_sources_settings: {
              data_sources_list: formState.isAllowSpecificDataSources
                ? formState.dataSourcesList
                : undefined,
              allow_or_disallow_data_sources: formState.isAllowSpecificDataSources
                ? formState.allowSelectedDataSources
                : undefined,
            },
          });
          break;
        default:
          break;
      }
      await dispatch(getClientFlags());
      message.success("Saved successfully");
    } catch (error) {
      message.error("Something went wrong. Please try again.");

      sentryCapture({
        error,
        name: "Update admin connection sharing settings : POST /api_admin_ext/settings",
      });
    }
  };

  const validateDomainEmail = (email: string) => {
    setErrorMessage("");
    if (!isValidEmail(email)) {
      return "Please provide a valid email.";
    }

    if (!domains_in_domain_family.includes(email.split("@")[1])) {
      return "Email ID doesn't belong to the same domain family.";
    }

    return;
  };

  const validateDomain = (domain: string) => {
    setErrorMessage("");

    if (!domains_in_domain_family.includes(domain)) {
      return "Domain doesn't belong to the same domain family.";
    }

    return;
  };

  const handleEmailChange = (tagList: string[]) => {
    setFormState({
      ...formState,
      usersEmailsList: tagList,
    });

    const hasError = tagList.some(tag => validateDomainEmail(tag));
    setHasError(hasError);
  };

  const handleDomainChange = (tagList: string[]) => {
    setFormState({
      ...formState,
      domainsList: tagList,
    });

    const hasError = tagList.some(tag => validateDomain(tag));
    setHasError(hasError);
  };

  const updateFilteredDomainUsersList = (query: string) => {
    setSearchValue(query);
    if (isLoadingDomainUsers || !domainUsersList) {
      return;
    }

    const allUsersList = domainUsersList.domainUsers.map(user => user.email);

    setFormState({
      ...formState,
      filteredDomainUserEmailsList: [],
    });
    const filteredDomainUsers: { value: string }[] = [];
    allUsersList?.forEach(userEmail => {
      const isMatchingQuery = stringSearch(userEmail, query);

      if (isMatchingQuery && userEmail) {
        filteredDomainUsers.push({ value: userEmail });
      }
    });
    setFormState({
      ...formState,
      filteredDomainUserEmailsList: filteredDomainUsers,
    });
  };

  const updateFilteredDomainsList = (query: string) => {
    setSearchValue(query);
    if (!domains_in_domain_family) {
      return;
    }
    setFormState({
      ...formState,
      filteredDomainsList: [],
    });
    const filteredDomains: { value: string }[] = [];
    domains_in_domain_family?.forEach(domain => {
      const isMatchingQuery = stringSearch(domain, query);

      if (isMatchingQuery) {
        filteredDomains.push({ value: domain });
      }
    });
    setFormState({
      ...formState,
      filteredDomainsList: filteredDomains,
    });
  };

  const isSingleDomain = domains_in_domain_family.length === 1;

  const getWarningMessage = (settingsType: SettingsType) => {
    switch (settingsType) {
      case "writeback_settings":
        return "Users from your domain(s) will no longer be able to export data to other systems.";
      case "slack_or_email_settings":
        return "Users from your domain(s) will no longer be able to send alerts to Slack or Email.";
      case "gpt_settings":
        return "Users from your domain(s) will no longer be able to access AI features.";
      case "cloud_table_settings":
        return "Users from your domain(s) will no longer be able to save spreadsheet data to Coefficient's secure cloud.";
      case "connection_sharing_settings":
        return "Users from your domain(s) will no longer be able to share connections with other users.";
      default:
        return undefined;
    }
  };

  const renderContent = () => {
    if (isLoadingAdminExtSettings) {
      return <LoaderWithPerfTimings name="WorkspaceAdminSettingsPopup" />;
    }

    return (
      <StyledContent>
        <StyledTypography color="textPrimary">
          {getTitleBasedOnSettingsType(settingsType)}
        </StyledTypography>
        <StyledContainer>
          <FlexBox>
            <StyledSpan>{getSettingDescriptionBasedOnType(settingsType)}</StyledSpan>
            {settingsType !== "allowed_data_sources_settings" && (
              <Switch
                className="styledToogle"
                size="small"
                checked={!Boolean(formState.isDisabled)}
                onChange={value => setFormState({ ...formState, isDisabled: !value })}
              />
            )}
          </FlexBox>

          {!Boolean(formState.isDisabled) && settingsType !== "connection_sharing_settings" && (
            <StyledRadioWrapper>
              {(settingsType === "allowed_data_sources_settings" ||
                !isSingleDomain ||
                settingsType === "writeback_settings") && (
                <Radio
                  className={formState.isAllowAllOption ? "selectionTitle" : ""}
                  checked={formState.isAllowAllOption}
                  onClick={() => {
                    setHasError(false);
                    setFormState({
                      ...formState,
                      isAllowAllOption: true,
                      isAllowSpecificDomains: false,
                      isAllowSpecificUserEmails: false,
                      isAllowSpecificDataSources: false,
                    });
                  }}
                >
                  {settingsType === "writeback_settings"
                    ? isSingleDomain
                      ? "All users"
                      : "All my domains & users"
                    : settingsType === "allowed_data_sources_settings"
                    ? "All data sources"
                    : "All my domains"}
                </Radio>
              )}

              {!isSingleDomain && settingsType !== "allowed_data_sources_settings" && (
                <>
                  <Radio
                    className={formState.isAllowSpecificDomains ? "selectionTitle" : ""}
                    checked={formState.isAllowSpecificDomains}
                    onClick={() => {
                      setFormState({
                        ...formState,
                        isAllowAllOption: false,
                        isAllowSpecificDomains: true,
                        isAllowSpecificUserEmails: false,
                        isAllowSpecificDataSources: false,
                      });
                    }}
                  >
                    Specific domains
                  </Radio>
                  {formState.isAllowSpecificDomains && (
                    <>
                      {showAllowOrDisallowDomainsDropdown && (
                        <Select
                          onChange={allowOption =>
                            setFormState({
                              ...formState,
                              allowSelectedDomains: allowOption,
                            })
                          }
                          defaultValue={AllowOrDisallowOption.Allow}
                          value={formState.allowSelectedDomains}
                        >
                          <Option value={AllowOrDisallowOption.Allow}>Allow these domains</Option>
                          <Option value={AllowOrDisallowOption.Disallow}>
                            Don't allow these domains
                          </Option>
                        </Select>
                      )}
                      <AutoCompleteTagsInput
                        placeholder="Enter domains"
                        onChange={tags => handleDomainChange(tags)}
                        validator={validateDomain}
                        tags={formState.domainsList}
                        onSearch={val => updateFilteredDomainsList(val)}
                        options={formState.filteredDomainsList}
                        validatorErrorText={
                          "One or more domains entered is invalid. Hover over each invalid email for more details."
                        }
                      />
                      {hasError && errorMessage && <ErrorDiv>{errorMessage}</ErrorDiv>}
                    </>
                  )}
                </>
              )}

              {settingsType === "writeback_settings" && (
                <>
                  <Radio
                    className={formState.isAllowSpecificUserEmails ? "selectionTitle" : ""}
                    checked={formState.isAllowSpecificUserEmails}
                    onClick={() => {
                      setFormState({
                        ...formState,
                        isAllowAllOption: false,
                        isAllowSpecificDomains: false,
                        isAllowSpecificUserEmails: true,
                        isAllowSpecificDataSources: false,
                      });
                    }}
                  >
                    Specific users
                  </Radio>

                  {formState.isAllowSpecificUserEmails && (
                    <>
                      {showAllowOrDisallowUsersDropdown && (
                        <Select
                          onChange={allowOption =>
                            setFormState({
                              ...formState,
                              allowSelectedUsersEmails: allowOption,
                            })
                          }
                          defaultValue={AllowOrDisallowOption.Allow}
                          value={formState.allowSelectedUsersEmails}
                        >
                          <Option value={AllowOrDisallowOption.Allow}>Allow these users</Option>
                          <Option value={AllowOrDisallowOption.Disallow}>
                            Don't allow these users
                          </Option>
                        </Select>
                      )}
                      <AutoCompleteTagsInput
                        placeholder="Enter emails"
                        onChange={tags => handleEmailChange(tags)}
                        validator={validateDomainEmail}
                        tags={formState.usersEmailsList}
                        onSearch={val => updateFilteredDomainUsersList(val)}
                        options={formState.filteredDomainUserEmailsList}
                      />
                      {hasError && errorMessage && <ErrorDiv>{errorMessage}</ErrorDiv>}
                    </>
                  )}
                </>
              )}

              {settingsType === "allowed_data_sources_settings" && sortedDataSources && (
                <>
                  <Radio
                    className={formState.isAllowSpecificDataSources ? "selectionTitle" : ""}
                    checked={formState.isAllowSpecificDataSources}
                    onClick={() => {
                      setFormState({
                        ...formState,
                        isAllowAllOption: false,
                        isAllowSpecificDomains: false,
                        isAllowSpecificUserEmails: false,
                        isAllowSpecificDataSources: true,
                      });
                    }}
                  >
                    Specific data sources
                  </Radio>

                  {formState.isAllowSpecificDataSources && (
                    <>
                      <Select
                        className="ds-settings"
                        onChange={allowOption =>
                          setFormState({
                            ...formState,
                            allowSelectedDataSources: allowOption,
                          })
                        }
                        defaultValue={AllowOrDisallowOption.Allow}
                        value={formState.allowSelectedDataSources}
                      >
                        <Option value={AllowOrDisallowOption.Allow}>
                          Allow these data sources
                        </Option>
                        <Option value={AllowOrDisallowOption.Disallow}>
                          Don't allow these data sources
                        </Option>
                      </Select>

                      <Select
                        className="ds-settings"
                        mode="multiple"
                        onChange={filtersDataSources =>
                          setFormState({
                            ...formState,
                            dataSourcesList: filtersDataSources,
                          })
                        }
                        placeholder="Select a data source"
                        value={formState.dataSourcesList}
                      >
                        {(sortedDataSources ?? [])?.map(dsType => (
                          <Option key={dsType.key} value={dsType.key}>
                            {dsType.name}
                          </Option>
                        ))}
                      </Select>
                    </>
                  )}
                </>
              )}
            </StyledRadioWrapper>
          )}
        </StyledContainer>
        {formState.isDisabled && getWarningMessage(settingsType) && (
          <StyledAlert
            type="warning"
            showIcon
            message="Warning"
            description={getWarningMessage(settingsType)}
          />
        )}
      </StyledContent>
    );
  };

  return (
    <Modal {...props} width={600}>
      {settingsType === undefined ? (
        <LoaderWithPerfTimings name="Settings" />
      ) : (
        <>
          <StyledContent>{renderContent()}</StyledContent>

          <ModalActions>
            <ModalButton
              $color={COLORS.white}
              disabled={
                isLoading ||
                isLoadingAdminExtSettings ||
                isLoadingUpdateAdminExtSettings ||
                (formState.isAllowSpecificUserEmails &&
                  (formState.usersEmailsList ?? []).length < 1) ||
                (formState.isAllowSpecificDomains && (formState.domainsList ?? []).length < 1) ||
                (formState.isAllowSpecificDataSources &&
                  (formState.dataSourcesList ?? []).length < 1) ||
                hasError
              }
              type="primary"
              onClick={() => handleSaveSettings()}
            >
              {isLoading ? "Saving..." : "Save"}
            </ModalButton>
          </ModalActions>
        </>
      )}
    </Modal>
  );
};
